import React from 'react'
import { connect } from 'react-redux';
import { Button, Grid } from '@material-ui/core'
import { PageTitle } from '../../_layouts/page-title';
import { DealAction } from '../../_redux/actions';
import { ArrowBack, Widgets } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { Flyer } from './flyer/Flyer';

function IncentiveTools({ loading, latest_deal }) {
    const navigate = useNavigate();

    const returnToDashboard = () => {
        navigate(`/home`);
    }

    const generateQrCode = () => {
        let pageUrl = `/incentive-tools/qr-code`;
        navigate(pageUrl);
    }

    return (
        <React.Fragment>
            <PageTitle title="Incentive Tools" action={
                <React.Fragment>
                    {<Button onClick={returnToDashboard} variant="contained" color="primary" startIcon={<ArrowBack />} >Back</Button>}
                    {<Button onClick={generateQrCode} variant="contained" color="primary" startIcon={<Widgets>Add</Widgets>} >Generate QR Code</Button>}
                </React.Fragment>
            } />
            {!loading && latest_deal._id && (
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Flyer latest_deal={latest_deal} />
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.DealReducer.latest_deal_loading,
        latest_deal: state.DealReducer.latest_deal,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLatestDeal: dispatch(DealAction.getLatestDeal())
    }
};

const connectedIncentiveTools = (connect(mapStateToProps, mapDispatchToProps)(IncentiveTools));
export { connectedIncentiveTools as IncentiveTools };
