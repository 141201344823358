import { isValidPhoneNumber } from "react-phone-number-input";

const validateProfileUpdate = (name, value, data = {}) => {
    //eslint-disable-next-line
    const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validInstaHandle = RegExp(/^@?[A-Z0-9_\\.]+$/i);
    const errors = {};
    switch (name) {
        case 'company_name': {
            errors.company_name = value.length < 3 ? 'Company name must be at leaset 3 characters long. ' : '';
            if (value === '')
                errors.company_name = 'Company name is required. ';
            break;
        } case 'insta_handle': {
            errors.insta_handle = validInstaHandle.test(value) ? '' : 'Public instagram handle is invalid. ';;
            if (value === '')
                errors.insta_handle = 'Public instagram handle is required. ';
            break;
        } case 'name': {
            errors.name = (value.length < 3) ? 'Company representative name must be atleaset 3 charecters. ' : '';
            if (value === '')
                errors.name = 'Company representative name is required ';
            break;
        } case 'email': {
            errors.email = validEmailRegex.test(value) ? '' : 'Company representative email is invalid. ';
            if (value === '')
                errors.email = 'Company representative email is required. ';
            break;
        } case 'company_url': {
            errors.company_url = '';
            if (value === '')
                errors.company_url = 'Company URL is required. ';
            break;
        } case 'company_phone': {
            errors.company_phone = isValidPhoneNumber(value) ? '' : 'The Company phone number is invalid. ';
            if (value === '')
                errors.company_phone = 'Company phone number is required. ';
            break;
        } default:
            errors[name] = '';
            break;
    }
    return errors;
}
export default validateProfileUpdate;