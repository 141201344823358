import { Card, CardContent, CardHeader } from '@material-ui/core';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PageTitle } from '../../../_layouts/page-title';
import { NewFollowersChart, DirectionClicksChart, ImpressionsChart, ProfileViewsChart, ReachChart, WebsiteClicksChart, PhoneCallClicksChart, EmailClicksChart, TextMessageClicksChart, DcDirectionClicksChart, DcImpressionsChart, DcPhoneCallClicksChart, DcReachChart, DcEmailClicksChart, DcTextMessageClicksChart, DcProfileViewsChart, DcNewFollowersChart, DcWebsiteClicksChart } from '../../ig-insights';

function InsightChart() {
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const metric = params.get('metric'); // plan promo from url
    const type = params.get('type'); // plan promo from url
    
    return(        
        <React.Fragment>
            <PageTitle title="Insights" />
            {!type &&  <React.Fragment>
                {metric==='follower_count' && 
                <NewFollowersChart/>
                }
                {metric==='impressions' && 
                    <ImpressionsChart/>
                }
                {metric==='profile_views' && 
                    <ProfileViewsChart/>
                }
                {metric==='reach' && 
                    <ReachChart/>
                }
                {metric==='website_clicks' && 
                    <WebsiteClicksChart/>
                }
                {metric==='phone_call_clicks' && 
                    <PhoneCallClicksChart/>
                }
                {metric==='email_contacts' && 
                    <EmailClicksChart/>
                }
                {metric==='text_message_clicks' && 
                    <TextMessageClicksChart/>
                }
                {metric==='get_directions_clicks' && 
                    <DirectionClicksChart/>
                }
            </React.Fragment>}
            {type==='dc' &&  <React.Fragment>
                {metric==='follower_count' && 
                    <DcNewFollowersChart/>
                }
                {metric==='impressions' && 
                    <DcImpressionsChart/>
                }
                {metric==='profile_views' && 
                    <DcProfileViewsChart/>
                }
                {metric==='reach' && 
                    <DcReachChart/>
                }
                {metric==='website_clicks' && 
                    <DcWebsiteClicksChart/>
                }
                {metric==='phone_call_clicks' && 
                    <DcPhoneCallClicksChart/>
                }
                {metric==='email_contacts' && 
                    <DcEmailClicksChart/>
                }
                {metric==='text_message_clicks' && 
                    <DcTextMessageClicksChart/>
                }
                {metric==='get_directions_clicks' && 
                    <DcDirectionClicksChart/>
                }
            </React.Fragment>}
            
        </React.Fragment>
    );

}


function mapStateToProps(state) {
    return {
       
    };
}

function mapDispatchToProps(dispatch) {
    return {
    }
};


const connectedInsightChart = (connect(mapStateToProps, mapDispatchToProps)(InsightChart));
export { connectedInsightChart as InsightChart };
