import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormCancelBtn, FormInput, FormSaveBtn } from '../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../_helpers';
import { UserAction } from '../../../_redux/actions';
import { RouteConfig } from '../../../_routes'
import validateChangePassword from './validateChangePassword';
import './style.scss'
import { FormGroupPassword } from '../../../_components/form-group-input';

function ProfileChangePassword({ ...props }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputs = { current_password: '', password: '', confirm_password: '' }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateChangePassword(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
    * function to handle submit action
    * @param object e input object
    * @author Akshay N
    */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateChangePassword(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchChangePasswordAction();
    }

    /**
    * function to dispatch signup action
    * @param object e input object
    * @author Akshay N
    */
    const dispatchChangePasswordAction = () => {
        dispatch(UserAction.changePassword(data))
            .then((response) => {
                if (response.status === 1) {
                    setAction({ isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors });
                    navigate('/profile');
                } else {
                    setErrors({ ...response.errors })
                    setAction({ isSubmitted: true, isProcessing: false });
                }
            });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const resetAndBack = (e) => {
        setData({ ...inputs });
        setErrors({ ...inputs });
        navigate("/profile");
    }



    return (
        <div className='profile-change-password'>
            <div className="mb-3">
                <h4 className="title" >{RouteConfig('profile_change_password').name}</h4>
            </div>
            <form onSubmit={handleSubmit} noValidate>
                <FormGroupPassword
                    type="password"
                    label="Current Password"
                    name="current_password"
                    tabIndex="1"
                    error={(action.isSubmitted && (errors.current_password)) ? (errors.current_password) : ''}
                    placeholder="Enter current password"
                    value={data.current_password}
                    onChange={handleChange}
                    className="col-md-6  mr-auto"
                    append='after'
                />
                <FormGroupPassword
                    type="password"
                    label="Password"
                    name="password"
                    tabIndex="2"
                    error={(action.isSubmitted && (errors.password)) ? (errors.password) : ''}
                    placeholder="Enter password"
                    value={data.password}
                    onChange={handleChange}
                    className="col-md-6  mr-auto"
                    append='after'
                />
                <FormGroupPassword
                    type="password"
                    label="Confirm Password"
                    name="confirm_password"
                    tabIndex="3"
                    error={(action.isSubmitted && (errors.confirm_password)) ? (errors.confirm_password) : ''}
                    placeholder="Re-enter password"
                    value={data.confirm_password}
                    onChange={handleChange}
                    className="col-md-6 mr-auto"
                    append='after'
                />
                <div className="col-md-12">
                    <FormCancelBtn onClick={resetAndBack} text="Cancel" />
                    <FormSaveBtn className="mr-1" isProcessing={action.isProcessing} />
                </div>
            </form>
        </div>
    )
}



function mapStateToProps(state) {
    return {
        user: state.UserReducer.user,
    };
}

const connectedProfileChangePassword = connect(mapStateToProps)(ProfileChangePassword);
export { connectedProfileChangePassword as ProfileChangePassword };



