import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { SignUpAction } from '../../../_redux/actions';
import { FormCheckbox, FormInput, FormPhoneInputNew } from '../../../_components/form-input';
import Config from '../../../_helpers/config';
import { handleInputChange, validateForm } from '../../../_helpers';
import validateSignUp from './validateInstaCreateAccount';

import { paperElevation } from '../../../config/config';
import Paper from '@material-ui/core/Paper';
import colors from '../../../theme/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import GuruLogo from '../../../components/GuruLogo';
import loginBackgroundImage from './../../../images/login_background.jpg';
import { Grid } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

function InstaCreateAccount({ ...props }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { approvalKey } = useParams();

    const inputs = { company_name: '', name: '', email: '', company_url: '', company_phone: '', terms_and_condition: false, approval_key: approvalKey }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, terms_and_condition: '', approval_key: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [formOpen, setFormOpen] = useState(0);


    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 30,
            maxWidth: 600
        },
        title: {
            marginBottom: 40,
            color: theme.palette.common.white,
        },
        textField: {
            '& .MuiFormLabel-root': {
                color: theme.palette.common.white,
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: 'white',
                    color: theme.palette.common.white,
                },
                '&.Mui-focused fieldset': {
                    borderColor: colors.secondary,
                    color: theme.palette.common.white,
                },
                '&:hover fieldset': {
                    borderColor: colors.secondary,
                    color: theme.palette.common.white,
                },
                color: theme.palette.common.white,
            },
            marginBottom: 15,
        },
        whiteColor: {
            color: theme.palette.common.white,
        },
        loginBtn: {
            marginTop: 40,
            marginBottom: 20,
            backgroundColor: colors.secondary,
        },
        link: {
            color: 'white',
            cursor: 'pointer',
        },
        signUpContainer: {
            backgroundImage: `url(${loginBackgroundImage})`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            width: '100%',
            display: 'flex',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
        }
    }));

    const styles = useStyles();

    useEffect(() => {
        document.title = `${Config.app_name} | Create Account`;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateSignUp(name, value, data)) });
        data[name] = value;
        setData({ ...data });
        setFormOpen(1);
    }

    /**
     * function to handle submit action
     * @param object e input object
     * @author Akshay N
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateSignUp(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchSignUpAction();
    }

    /**
    * function to dispatch signup action
    * @param object e input object
    * @author Akshay N
    */
    const dispatchSignUpAction = () => {
        dispatch(SignUpAction.instaSignUp(data))
            .then((response) => {
                if (response.status === 1) {
                    setAction({ ...action, isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors })
                    setData({ ...inputs });
                    let redirectPath = (`/payment-preference/${response.data.approval_key}`);
                    const { from } = { from: { pathname: redirectPath } };
                    navigate(from);
                } else {
                    setErrors({ ...response.errors })
                    setAction({ ...action, isSubmitted: true, isProcessing: false });
                }
            });
    }


    return (
        <div className={`flex-column form-sm full-width full-height justify-center align-items-center ${styles.signUpContainer}`}>
            <Paper classes={{ root: styles.root }} elevation={paperElevation}>
                <GuruLogo />
                <Typography
                    color={'primary'}
                    classes={{ root: styles.title }}
                    variant={'h3'}
                >
                    Create Account
                </Typography>
                <form onSubmit={handleSubmit} noValidate className="form-row auth-form">
                    <Grid container >
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                            <FormInput
                                label="Company Name"
                                placeholder="Enter company name"
                                name="company_name"
                                tabIndex="1"
                                value={data.company_name}
                                onChange={handleChange}
                                error={(action.isSubmitted && (errors.company_name)) ? (errors.company_name) : ''}
                                maxLength={100}
                            // maskType={`alphabets-with-space`}
                            />
                        </Grid>
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                            <FormInput
                                label="Company Representative Name"
                                placeholder="Enter company representative name"
                                name="name"
                                tabIndex="3"
                                value={data.name}
                                onChange={handleChange}
                                error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                maxLength={100}
                                maskType={`alphabets-with-space`}
                            />
                        </Grid>
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                            <FormInput
                                label="Company Representative Email"
                                name="email"
                                type="email"
                                tabIndex="4"
                                error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                                placeholder="Enter company representative email"
                                value={data.email}
                                onChange={handleChange}
                                maxLength={100}
                            />
                        </Grid>
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                            <FormInput
                                label="Company URL"
                                name="company_url"
                                tabIndex="5"
                                error={(action.isSubmitted && (errors.company_url)) ? (errors.company_url) : ''}
                                placeholder="Enter company URL"
                                value={data.company_url}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                            <FormPhoneInputNew
                                label="Company Phone Number"
                                name="company_phone"
                                tabIndex="6"
                                error={(action.isSubmitted && (errors.company_phone)) ? (errors.company_phone) : ''}
                                placeholder="Enter company phone number"
                                value={data.company_phone}
                                onChange={handleChange}
                                formOpen={formOpen}
                            />
                        </Grid>
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                            <FormCheckbox
                                label="I accept the <a href='javascript:void(0)'>terms and conditions.</a>"
                                name="terms_and_condition"
                                tabIndex="9"
                                checked={data.terms_and_condition}
                                error={(action.isSubmitted && (errors.terms_and_condition)) ? (errors.terms_and_condition) : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
                            <div className="form-group mb-0 form-group-action d-flex align-items-center justify-content-between">
                                <button type="submit" className="btn btn-warning btn-submit btn-icon icon-right" tabIndex="10" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Register`}</button>
                            </div>
                        </Grid>
                    </Grid>
                </form>

            </Paper>
        </div>
    )
}



const connectedSignUp = connect(null)(InstaCreateAccount);
export { connectedSignUp as InstaCreateAccount };
