import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";

export const UserServices = { getUser, updateUser, changePassword, logout, getTotalProfileViews, getTotalNewFollowers };

/**
 * Service for get user 
 *
 * @author Akshay 
 */
function getUser() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/business`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get user 
 *
 * @author Akshay 
 */
function updateUser(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/ns/business/update`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message)
            return response;
        })
}

/**
 * Service for get user 
 *
 * @author Akshay 
 */
function changePassword(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/ns/business/change-password`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message)
            return response;
        })
}

/**
 * function get total profile views
 * @param params
 * @return view
 * @author Jasin
 * @created_at 24/09/2022
 */
 function getTotalProfileViews(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/business/total-profile-views`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function get total new followers
 * @param params
 * @return view
 * @author Jasin
 * @created_at 24/09/2022
 */
 function getTotalNewFollowers(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/business/new-follower-count`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function description
 * @param Null
 * @return view
 * @author Akshay N
 * @created_at 
 */
function logout() {
    // const requestOptions = {};
    // requestOptions.headers = authHeader();
    // requestOptions.method = 'POST';
    // return fetch(`${Config.endpoint}/logout`, requestOptions)
    //     .then(handleResponse)
    //     .then((result) => {
    //         return result;
    //     });
    return true;
}