import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";
import queryString from 'query-string';


export const InvoicesServices = { getInvoicesList, generateInvoice, makePayment };

/**
 * function get shoppers list
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function getInvoicesList(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    let queryParam = {};
    queryParam.page = params.page;
    if (params.search) queryParam.search = params.search; else delete queryParam.search;

    return fetch(`${Config.endpoint}/ns/payments/get-all-invoices?${queryString.stringify(queryParam)}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * function get shoppers list
 * @param params
 * @return view
 * @author Akshay N
 * @created_at 02/09-2021
 */
function generateInvoice(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    return fetch(`${Config.endpoint}/ns/payments/generate-invoice`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

function makePayment(invoiceId) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify({ 'invoice_id': invoiceId });

    return fetch(`${Config.endpoint}/ns/payments/make-payment`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}
