import { GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAILURE, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE,
   GET_TOTAL_PROFILE_VIEWS_REQUEST,GET_TOTAL_PROFILE_VIEWS_SUCCESS,GET_TOTAL_PROFILE_VIEWS_FAILURE,
   GET_TOTAL_NEW_FOLLOWERS_REQUEST,GET_TOTAL_NEW_FOLLOWERS_SUCCESS,GET_TOTAL_NEW_FOLLOWERS_FAILURE,
 } from "../actions";

let user = localStorage.getItem('authToken') ? (localStorage.getItem('authToken')) : '';
const initialState = user ? { loggedIn: true, loading: false, user , total_profile_views_loading: false, total_profile_views: 0, total_profile_views_error: [], total_new_followers_loading: false, total_new_followers: 0, total_new_followers_error: []} : { loggedIn: false, loading: false, user: {} };

export function UserReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::User redux */
        case GET_USER_REQUEST:
            return {
                loggedIn: false,
                loading: true,
                user: {}
            };
        case GET_USER_SUCCESS:
            return {
                loggedIn: false,
                loading: false,
                user: action.data,
            };
        case GET_USER_FAILURE:
            return {
                loggedIn: false,
                loading: false,
                user: {}
            };
        /** end::User redux */


        /** begin::User redux */
        case UPDATE_USER_REQUEST: {
            return {
                ...state,
                request: action.request,
            };
        } case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                user: {
                    profile_image: state.user.profile_image,
                    ...action.data
                },
            };
        } case UPDATE_USER_FAILURE: {
            return {
                ...state,
                errors: action.errors
            };
        }
        /** end::User redux */

        case GET_TOTAL_PROFILE_VIEWS_REQUEST: {
            return {
                ...state,
                total_profile_views_loading: true,
                total_profile_views_error: []
            };
        }
        case GET_TOTAL_PROFILE_VIEWS_SUCCESS: {
            return {
                ...state,
                total_profile_views_loading: false,
                total_profile_views: action.data.total_profile_views,
                total_profile_views_error: []
            };
        }
        case GET_TOTAL_PROFILE_VIEWS_FAILURE: {
            return {
                ...state,
                total_profile_views_loading: false,
                total_profile_views_error: action.error
            };
        }

        case GET_TOTAL_NEW_FOLLOWERS_REQUEST: {
            return {
                ...state,
                total_new_followers_loading: true,
                total_new_followers_error: []
            };
        }
        case GET_TOTAL_NEW_FOLLOWERS_SUCCESS: {
            return {
                ...state,
                total_new_followers_loading: false,
                total_new_followers: action.data.total_profile_views,
                total_new_followers_error: []
            };
        }
        case GET_TOTAL_NEW_FOLLOWERS_FAILURE: {
            return {
                ...state,
                total_new_followers_loading: false,
                total_new_followers_error: action.error
            };
        }


        default:
            return state;
    }
}