import { Card, CardContent, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles({
    paper: {
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

function SummaryWidget({ loading, text, value, bgColor, color, subText }) {

    const classes = useStyles();

    return (
        <Card style={{ backgroundColor: bgColor, color: color }}>
            <CardContent>
                <React.Fragment>
                    <Typography className={classes.title} gutterBottom dangerouslySetInnerHTML={{ __html: text }} />
                    <Typography variant="h5" component="h2" dangerouslySetInnerHTML={{ __html: (loading ? '<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>' : value) }} />
                    {subText && <Typography>{subText}</Typography>}
                </React.Fragment>
            </CardContent>
        </Card>
    )
}

export { SummaryWidget }
