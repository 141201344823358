import { Button } from '@material-ui/core';
import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SignInAction } from '../../../../_redux/actions';

function FinishRegisteration({ loading, defaultPayment }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();


    const { approvalKey } = useParams();

    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const register = () => {
        setAction({ isSubmitted: true, isProcessing: true });

        dispatch(SignInAction.finishRegisteration(approvalKey))
            .then((response) => {
                if (response.status === 1) {
                    // let redirectPath = ((props.location.state && props.location.state.from.pathname) ? (props.location.state.from.pathname) : '/');
                    let redirectPath = ('/home');
                    const { from } = { from: { pathname: redirectPath } };
                    navigate(from);
                    window.location.reload();
                } else {
                    setAction({ isSubmitted: true, isProcessing: false });
                }
            });
    }

    return (
        <React.Fragment>
            {!loading && defaultPayment && (defaultPayment === 'card' || defaultPayment === 'bank') &&
                <button type="button" className="btn btn-warning btn-submit btn-icon icon-right" onClick={register} tabIndex="10" disabled={action.isProcessing}>{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : `Register`}</button>
            }
        </React.Fragment >
    )
}


function mapStateToProps(state) {
    return {
        loading: state.InstaPaymentReducer.payment_details_loading,
        defaultPayment: state.InstaPaymentReducer.payment_details.default_payment
    };
}

const connectedFinishRegisteration = connect(mapStateToProps, null)(FinishRegisteration);
export { connectedFinishRegisteration as FinishRegisteration };
