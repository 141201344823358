import React from 'react'
import { Box, Grid } from '@material-ui/core';
import { DataTable } from '.';
import { connect } from 'react-redux';
import { Payments } from '../payments';
import { PageTitle } from '../../_layouts/page-title';
import { Card, CardHeader, CardContent } from '../../_components/card';

function Invoices() {

    return (
        <Box>
            <PageTitle title="Statements" />
            <Payments />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardHeader title="Statements List" />
                        <CardContent>
                            <DataTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </Box>
    )
}


const connectedInvoices = connect(null, null)(Invoices);
export { connectedInvoices as Invoices };
