import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { DataTable } from './DataTable'

import { Box, Grid, makeStyles, Paper } from '@material-ui/core';

import { GenerateWinner, GiveawayEntries } from '.';
import { PageTitle } from '../../_layouts/page-title';
import { Card, CardHeader, CardContent } from '../../_components/card';


const useStyles = makeStyles((theme) => ({
    giveawayGenerateWinnersContainer: {
        marginBottom: theme.spacing(3)
    },
    giveawayWinnersContainer: {
        marginBottom: theme.spacing(3)
    },
    giveawayEntriesContainer: {
        marginBottom: theme.spacing(2)
    }
}))



function Winners() {


    const classes = useStyles()

    let page = 0;


    useEffect(() => {
        // document.title = `${Config.app_name} | asd`;
    }, [])

    const onSelect = (selectedData) => {

    }



    return (
        <React.Fragment>

            <PageTitle title={'Giveaways'} />

            <Box className={classes.giveawayGenerateWinnersContainer}>
                <Grid container spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                        <GenerateWinner />
                    </Grid>
                </Grid>
            </Box>

            <Box className={classes.giveawayWinnersContainer}>
                <Paper elevation={1} >
                    <Card>
                        <CardHeader title="Giveaway Winners" />
                        <CardContent>
                            <DataTable page={page} search={''} filter={''} sort={''} handleSelect={onSelect} />
                        </CardContent>
                    </Card>
                </Paper>
            </Box>

            <Box className={classes.giveawayEntriesContainer}>
                <Paper elevation={1} >
                    <Card>
                        <CardHeader title="Giveaway Entries" />
                        <CardContent>
                            <GiveawayEntries />
                        </CardContent>
                    </Card>
                </Paper>
            </Box>
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        page: state.GiveawayWinnerReducer.page,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // addWinners: (params) => dispatch(CouponAction.addWinners(params))
    }
};

const connectedCoupon = (connect(mapStateToProps, mapDispatchToProps)(Winners));
export { connectedCoupon as Winners };

