import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SummaryWidget } from '../../../_components/summary-widget';

function NewFollowersCount({ isLoading, new_followers }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=follower_count`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily New Followers'} value={new_followers} bgColor={"#0ec164"} color={"#ffffff"}/>
        </Box>
    );
}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedNewFollowersCount = (connect(mapStateToProps, mapDispatchToProps)(NewFollowersCount));
export { connectedNewFollowersCount as NewFollowersCount };
