import "react-datepicker/dist/react-datepicker.css";

import { FormControl, FormHelperText, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types'
import moment from 'moment'
const useStyles = makeStyles({
    formControl: {
        // ...FormControl
    },
    label: {

    },
    input: {
        // border: `1px solid ${grayColor[4]}`,
        fontSize: 16,
        width: 'auto',
        padding: '10px 15px',
        height: `38px !important`,
        borderRadius: `.25rem`,
        borderColor: '#cccccc',
        '&:before': {
            border: `unset`
        },
        '&:before &:hover': {
            border: `unset`
        }
    },
    info: {
        fontSize: 12,
        marginTop: 0
    },
    someCustomClass: {
        zIndex: 100
    },
    pickerStyle: {
        width: '100%',
        borderColor: '#cccccc',
        height: '38px',
        borderStyle: `solid`
    },
    pickerStyleDisabled: {
        width: '100%',
        borderColor: '#cccccc',
        height: '38px',
        cursor: 'not-allowed',
        paddingLeft: `10px`,
        paddingRight: `10px`,
        borderRadius: `5px`,
        borderWidth: `1px`,
        borderStyle: `solid`,
        backgroundColor: `#f8f8f8`

    }
})

function FormDateTimePickerNew({ label, name, value, placeholder, className, onChange, tabIndex, id, error, isReadOnly, pattern, maxLength, maskType, info, ...props }) {
    const [startDate, setStartDate] = useState(value ? value : '');

    const classes = useStyles();

    const handleChange = (e) => {
        setStartDate(e)
        onChange({ target: { type: `date_time`, name: name, value: moment(e).format('yyyy-MM-DDTHH:mm') } });
    }
    return (
        <FormControl fullWidth className={`${className}`} style={{ paddingLeft: `15px`, paddingRight: `15px` }}>
            <div className="row">
                <div id="div2" className='col-12'>
                    <label htmlFor={name} dangerouslySetInnerHTML={{ __html: label }} ></label>
                    {/* <InputLabel shrink htmlFor={name} className={classes.label} style={{ marginBottom: `5px`, paddingLeft: `15px`, paddingRight: `15px`, color: `#000` }} >{label}</InputLabel> */}
                </div>
                <div id="div1" className='col-12' style={{ cursor: isReadOnly ? 'not-allowed' : 'auto' }}>
                    <DatePicker
                        selected={isReadOnly ? '' : startDate}
                        onChange={handleChange}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        wrapperClassName={classes.pickerStyle}
                        className={isReadOnly ? classes.pickerStyleDisabled : classes.pickerStyle}
                        popperClassName={classes.someCustomClass}
                        onChangeRaw={(e) => e.preventDefault()}
                        shouldCloseOnSelect={false}
                        yearDropdownItemNumber={3}
                        scrollableYearDropdown={true}
                        showYearDropdown
                        showMonthDropdown
                        readOnly={isReadOnly}
                        placeholderText="Select giveaway ends in"
                    />
                </div>
            </div>

            <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>
            {(error) && <FormHelperText id={`helper_${name}`} error className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`helper_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl >
    )
}


FormDateTimePickerNew.defaultProps = {
    className: "",
    isReadOnly: false,
    maxLength: 255,
    maskType: ''
}

FormDateTimePickerNew.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.string,
    id: PropTypes.string,
    isReadOnly: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    maxLength: PropTypes.number,
    maskType: PropTypes.string
}

export { FormDateTimePickerNew }
