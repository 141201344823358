import React from 'react'
import { connect } from 'react-redux';

import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((theme) => ({
    label: {
        paddingBottom: theme.spacing(1)
    }
}))

function SubdomainDetails({ loading, brand_sub_domain }) {

    const classes = useStyles();

    if (loading) {
        return (
            <Grid container alignItems="flex-start">
                <Grid item xs={12} sm={12} lg={12} md={12} xl={12} >
                    <CircularProgress size={`3rem`} />
                </Grid>
            </Grid>
        )
    }

    if (brand_sub_domain) {
        return (
            <Grid container alignItems="flex-start">
                <Grid item xs={12} sm={12} lg={12} md={12} xl={12} >
                    <Typography variant="subtitle1" component="h6" color="initial" className={classes.label}>Your shopper app URL:</Typography>
                    <a href={`${process.env.REACT_APP_HTP}${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`} target="_blank" rel="noopener noreferrer"><Typography variant="h4" color="initial" style={{ wordWrap: 'break-word' }}>{`${process.env.REACT_APP_HTP}${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`}</Typography></a>
                </Grid>
            </Grid>
        );
    } else {
        return (
            <Grid container alignItems="flex-start">
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
                    <Typography variant="h6" component="h6" color="initial" className={classes.label}>Please generate your subdomain</Typography>
                </Grid>
            </Grid>
        );
    }

}


function mapStateToProps(state) {
    return {
        loading: state.SettingsReducer.settings_loading,
        brand_sub_domain: state.SettingsReducer.brand_sub_domain
    };
}


const connectedSubdomainDetails = connect(mapStateToProps, null)(SubdomainDetails);
export { connectedSubdomainDetails as SubdomainDetails };

