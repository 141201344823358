import {
    GET_DEALS_REQUEST, GET_DEALS_SUCCESS, GET_DEALS_FAILURE,
    GET_LATEST_DEAL_REQUEST, GET_LATEST_DEAL_SUCCESS, GET_LATEST_DEAL_FAILURE,
    DELETE_DEALS_REQUEST, DELETE_DEALS_SUCCESS, DELETE_DEALS_FAILURE,
    GET_DEAL_REQUEST, GET_DEAL_SUCCESS, GET_DEAL_FAILURE,
    UPDATE_STATUS_REQUEST, UPDATE_STATUS_SUCCESS, UPDATE_STATUS_FAILURE,
    GET_MAIL_CONTENT_REQUEST, GET_MAIL_CONTENT_SUCCESS, GET_MAIL_CONTENT_FAILURE, CLEAR_MAIL_CONTENT,
    SET_PAGE,
    EDIT_DEALS_REQUEST, EDIT_DEALS_SUCCESS, EDIT_DEALS_FAILURE,
    ADD_DEALS_REQUEST, ADD_DEALS_SUCCESS, ADD_DEALS_FAILURE
} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    deal_loading: false, deals: [], deal_error: [],
    latest_deal_loading: false, latest_deal: {}, latest_deal_error: {},
    mail_content_loading: true, mail_content: {}, mail_content_error: {}
};

export function DealReducer(state = initialState, action) {
    switch (action.type) {
        /** begin::Deal redux */
        case GET_DEAL_REQUEST: {
            return {
                ...state,
                deal_error: []
            };
        } case GET_DEAL_SUCCESS: {
            return {
                ...state,
                latest_deal: action.data,
            };
        } case GET_DEAL_FAILURE: {
            return {
                ...state,
            };
        }
        /** end::Deal redux */

        /** begin::Deal redux */
        case GET_LATEST_DEAL_REQUEST: {
            return {
                ...state,
                latest_deal_loading: true,
                latest_deal_error: {}
            };
        } case GET_LATEST_DEAL_SUCCESS: {
            return {
                ...state,
                latest_deal_loading: false,
                latest_deal: action.data,
                latest_deal_error: {}
            };
        } case GET_LATEST_DEAL_FAILURE: {
            return {
                ...state,
                latest_deal_loading: false,
                latest_deal_error: action.error
            };
        }
        /** end::Deal redux */

        /* Begin::Edit deal redux */
        case EDIT_DEALS_REQUEST: {
            return {
                ...state,
                latest_deal_loading: true,
                latest_deal_error: {}
            };
        } case EDIT_DEALS_SUCCESS: {
            return {
                ...state,
                latest_deal_loading: false,
                latest_deal: action.data,
                latest_deal_error: {}
            };
        } case EDIT_DEALS_FAILURE: {
            return {
                ...state,
                latest_deal_loading: false,
                latest_deal_error: action.error
            };
        }
        /* End::Edit deal redux */

        /* Begin::Edit deal redux */
        case ADD_DEALS_REQUEST: {
            return {
                ...state,
                latest_deal_loading: true,
                latest_deal_error: {}
            };
        } case ADD_DEALS_SUCCESS: {
            return {
                ...state,
                latest_deal_loading: false,
                latest_deal: action.data,
                latest_deal_error: {}
            };
        } case ADD_DEALS_FAILURE: {
            return {
                ...state,
                latest_deal_loading: false,
                latest_deal_error: action.error
            };
        }
        /* End::Edit deal redux */




        /** begin::Deal redux */
        case GET_DEALS_REQUEST: {
            return {
                ...state,
                deal_loading: true,
                deals: [],
                deal_error: []
            };
        } case GET_DEALS_SUCCESS: {
            return {
                ...state,
                deal_loading: false,
                deals: action.data.deals,
                deal_error: [],
                page: action.data.page,
                per_page: action.data.per_page,
                total: action.data.total,
                total_pages: action.data.total_pages,
            };
        } case GET_DEALS_FAILURE: {
            return {
                ...state,
                deal_loading: false,
                deals: [],
                deal_error: []
            };
        }
        /** end::Deal redux */

        /** begin::Deal delete redux */
        case DELETE_DEALS_REQUEST: {
            return {
                ...state,
                deal_loading: true,
                deal_error: []
            };
        } case DELETE_DEALS_SUCCESS: {
            // const deals = state.deals.filter((deal, i) => deal.en_id !== action.id)
            return {
                ...state,
                deal_loading: false,
                // deals: deals,
                deal_error: [],
                // total: (state.total - 1),
            };
        } case DELETE_DEALS_FAILURE: {
            return {
                ...state,
                deal_loading: false,
                deals: [],
                deal_error: []
            };
        }
        /** end::Deal delete redux */

        /** begin::Deal delete redux */
        case UPDATE_STATUS_REQUEST: {
            return {
                ...state,
                deal_error: []
            };
        } case UPDATE_STATUS_SUCCESS: {

            const dealIndex = state.deals.findIndex(deal => deal._id === action.data._id); //finding index of the item
            let newDealsArray = [...state.deals]; //making a new array
            if (dealIndex !== -1) {
                newDealsArray[dealIndex].status = action.data.status//changing value in the new array
                // newDealsArray[dealIndex].isLoading = false;
            } else {
                newDealsArray = newDealsArray.concat(action.data);
            }

            return {
                ...state,
                deals: newDealsArray,
                deal_error: [],
            };
        } case UPDATE_STATUS_FAILURE: {
            return {
                ...state,
                deal_error: []
            };
        }
        /** end::Deal delete redux */


        /** begin::Deal delete redux */
        case GET_MAIL_CONTENT_REQUEST: {
            return {
                ...state,
                mail_content_loading: true,
                mail_content: {},
                mail_content_error: []
            };
        } case GET_MAIL_CONTENT_SUCCESS: {
            return {
                ...state,
                mail_content_loading: false,
                mail_content: action.data,
                mail_content_error: [],
            };
        } case GET_MAIL_CONTENT_FAILURE: {
            return {
                ...state,
                mail_content_loading: false,
                mail_content: {},
                mail_content_error: []
            };
        }
        /** end::Deal delete redux */

        case CLEAR_MAIL_CONTENT: {
            return {
                ...state,
                mail_content_loading: true,
                mail_content: {},
                mail_content_error: []
            };
        }


        case SET_PAGE: {
            return {
                ...state,
                page: action.page
            };
        }

        default:
            return state;
    }
}