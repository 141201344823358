import { authHeader, handleResponse, notify } from "../../_helpers";
import Config from "../../_helpers/config";
import queryString from 'query-string';


export const CouponServices = { getCoupon, getCoupons, deleteCoupons,deleteActiveCoupons, addCoupons, editCoupons, importCoupons, updateStatus, minimumCoupons, getMinimumCoupons };


/**
 * Service for get coupons 
 *
 * @author Akshay 
 */
function getCoupon(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/coupons/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for get coupons 
 *
 * @author Akshay 
 */
function getCoupons(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';

    let queryParam = {};
    queryParam.page = params.page;
    if (params.search) queryParam.search = params.search; else delete queryParam.search;

    return fetch(`${Config.endpoint}/ns/coupons?${queryString.stringify(queryParam)}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}

/**
 * Service for delete coupon 
 *
 * @author Madhav 
 */
function deleteActiveCoupons() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'DELETE';
    return fetch(`${Config.endpoint}/ns/coupons/delete-coupon-code`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}
/**
 * Service for delete coupon 
 *
 * @author Akshay 
 */
function deleteCoupons(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'DELETE';

    return fetch(`${Config.endpoint}/ns/coupons/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}
/**
 * Service for add coupon 
 *
 * @author Akshay 
 */
function addCoupons(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'POST';
    requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/coupons/create`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for add coupon 
 *
 * @author Akshay 
 */
function editCoupons(id, params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'PUT';
    requestOptions.body = JSON.stringify(params);

    return fetch(`${Config.endpoint}/ns/coupons/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for add coupon 
 *
 * @author Akshay 
 */
function updateStatus(id) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'PUT';

    return fetch(`${Config.endpoint}/ns/coupons/status/${id}`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for import coupons
 *
 * @author Akshay 
 */
function importCoupons(params) {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    delete requestOptions.headers['Content-Type'];
    requestOptions.headers['form-data'] = true;
    requestOptions.method = 'POST';
    requestOptions.body = (params);

    return fetch(`${Config.endpoint}/ns/coupons/import`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for add coupon 
 *
 * @author Jasin 
 */
 function minimumCoupons(params) {
     const requestOptions = {};
     requestOptions.headers = authHeader();
     requestOptions.method = 'POST';
     requestOptions.body = JSON.stringify(params);
    return fetch(`${Config.endpoint}/ns/coupons/min-coupons`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            notify(response.message);
            return response;
        })
}

/**
 * Service for add coupon 
 *
 * @author Jasin 
 */
function getMinimumCoupons() {
    const requestOptions = {};
    requestOptions.headers = authHeader();
    requestOptions.method = 'GET';
    return fetch(`${Config.endpoint}/ns/coupons/get-min-coupons`, requestOptions)
        .then(handleResponse)
        .then((response) => {
            return response;
        })
}
