import React from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

function FormDateRangePicker({ label, name, value, placeholder, tabIndex, id, onApply, error, className }) {

    const DATE_FORMAT = 'MM/DD/YYYY';

    const handleApply = (event, picker) => {
        picker.element.val(
            picker.startDate.format(DATE_FORMAT) + ' - ' + picker.endDate.format(DATE_FORMAT)
        );
        onApply(event);
    };

    const handleCancel = (event, picker) => {
        picker.element.val('');
        onApply(event);
    };

    return (
        <div className={`form-group ${className}`}>
            <label htmlFor={name}>{label}</label>
            <DateRangePicker
                onApply={handleApply}
                onCancel={handleCancel}
                initialSettings={{
                    autoUpdateInput: false,
                    locale: {
                        format: DATE_FORMAT,
                    },
                }}>
                <input type="text" className={`form-control ${(error) ? `is-invalid` : ``}`} name={name} placeholder={placeholder} tabIndex={tabIndex} id={id} defaultValue={value} readOnly />
            </DateRangePicker>
            {(error) && (<div className="invalid-feedback">{error}</div>)}
        </div>
    )
}


FormDateRangePicker.defaultProps = {
    type: "text",
    className: ""
}

FormDateRangePicker.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    tabIndex: PropTypes.string,
    id: PropTypes.string,
    onApply: PropTypes.func.isRequired
}

export { FormDateRangePicker }
