import React from 'react'
import { Box, Button } from '@material-ui/core';
import { notify } from '../../../_helpers';
import { PageTitle } from '../../../_layouts/page-title';
const copy = require('clipboard-copy')


function IncentiveWidget({ src }) {


    const inputs = { width: '400', height: '650' }

    const copyToClipBoard = (e) => {
        e.preventDefault();
        const url = `<iframe src="${src}" width="${inputs.width}" height="${inputs.height}"  scrolling="yes" ></iframe>`
        copy(url);
        notify('Copied to clipboard.');

    }

    return (
        <React.Fragment>
            <PageTitle title="Widget" />
            <Box display={`flex`} alignItems={`center`} justifyContent={`center`}>
                <div className="row">
                    <div className="col-md-12">
                        {`<iframe src="${src}" width="${inputs.width}" height="${inputs.height}" scrolling="yes" ></iframe>`}
                    </div>
                </div>
            </Box>
            <Box style={{ textAlign: 'end'}}>
                <Button onClick={copyToClipBoard} type="button" variant="contained" color="primary" >Copy</Button>
            </Box>
        </React.Fragment>
    )
}

export { IncentiveWidget }