import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux';

import { CardHeader, CardContent } from '../../../_components/card';
import { Box, CircularProgress, Grid } from '@material-ui/core';

const ShopperApp = lazy(() => import("./ShopperApp"));

function DealPreview({ brand_sub_domain }) {


    return (
        <Box>
            <CardHeader
                title="Mobile Preview"
                titleTypographyProps={{ variant: 'h4', component: 'h4' }}
            />
            <CardContent>
                <Box>
                    <Grid container direction="column" justifyContent="center" alignItems="center" >
                        <Grid item sx={12} sm={12} md={12} lg={12} xl={12} >
                            <Suspense fallback={<CircularProgress />}>
                                <ShopperApp src={`${process.env.REACT_APP_HTP}${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`} class="deal-iframe" width="400" height={650} />
                            </Suspense>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Box>
    )
}


function mapStateToProps(state) {
    return {
        brand_sub_domain: state.SettingsReducer.brand_sub_domain
    };
}


const connectedDealPreview = (connect(mapStateToProps, null)(DealPreview));
export { connectedDealPreview as DealPreview };


