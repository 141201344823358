import React from 'react'
import { connect } from 'react-redux';
import { Button, Card, CardContent, Grid } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { PageTitle } from '../../../_layouts/page-title';
import { IncentiveWidget } from './IncentiveWidget';
import { GenerateQrCode } from '../../home';

function IncentiveQrCode({ brand_sub_domain }) {
    const navigate = useNavigate();

    const returnToIncentiveTools = () => {
        navigate(`/incentive-tools`);
    }

    return (
        <React.Fragment>
            <PageTitle title="Incentive Tools" action={
                <React.Fragment>
                    { <Button onClick={returnToIncentiveTools} variant="contained" color="primary" startIcon={<ArrowBack />} >Back</Button>}
                </React.Fragment>
            }/>
            <Grid container spacing={3}  >
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                    <Card>
                        <CardContent>
                            <Grid container spacing={1}  >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>              
                                    <GenerateQrCode brand_sub_domain={brand_sub_domain}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                    <Card>
                        <CardContent>
                            <Grid container spacing={1}  >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>              
                                    <IncentiveWidget src={`${process.env.REACT_APP_HTP}${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
        brand_sub_domain: state.SettingsReducer.brand_sub_domain
    };
}

function mapDispatchToProps(dispatch) {
    return {
    }
};

const connectedIncentiveQrCode = (connect(mapStateToProps, mapDispatchToProps)(IncentiveQrCode));
export { connectedIncentiveQrCode as IncentiveQrCode };
