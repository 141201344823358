import React, { FC, useEffect, useState } from 'react';
import './BaseRouter.scss';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import CookieServices from '../_redux/services/CookieServices';

import LoadingIndicator from '../components/LoadingIndicator';
import { ApproveBusiness, ForgotPassword, InstaSignIn, SignIn, SignUp, InstaCreateAccount, InstaPaymentPreference } from '../app/pre-signin';
import NsDrawerNavigation from '../_components/drawer-navigation';

import { Settings } from '../app/settings';
import { Profile } from '../app/profile';
import { Home, GenerateQrCode } from '../app/home';
import { useDispatch } from 'react-redux';
import { SettingsAction, UserAction } from '../_redux/actions';
import { navigate } from '@reach/router';
import { handleInputChange, validateForm } from '../_helpers';
import validateSubDomain from '../app/settings/sub-domain/validateSubDomain';

import { Modal } from 'react-bootstrap';
import { FormGroupInput } from '../_components/form-group-input';
import { FormCheckbox } from '../_components/form-input';
import { Deal, AddUpdateDeal } from '../app/deals';
import { Coupons } from '../app/coupons';
import { Winners } from '../app/giveaway/Winners';
import { Shoppers, Shopper } from '../app/shoppers';
import { GiveawayParticipants } from '../app/giveaway';
import { Stories } from '../app/stories';
import { Payments } from '../app/payments'
import { Invoices } from '../app/invoices';
import { ContactSupport } from '../app/contact-support';

import { drawerNavigationWidth } from '../config/config';
import { Box, makeStyles } from '@material-ui/core';
import { IncentiveTools, IncentiveQrCode } from '../app/incentive-tools';
import { IgInsights, InsightChart } from '../app/ig-insights';
import { PrivacyPolicy, Tos } from '../app/pre-signin/tos-privacy-policy';



// app bar
const useStyles = makeStyles((theme) => ({
	appContainer: {
		marginLeft: drawerNavigationWidth,
		padding: theme.spacing(6),
		"@media (max-width: 768px)": {
			marginLeft: 'unset',
			padding: theme.spacing(3),
			paddingTop: theme.spacing(12)
		}
	},
	toolbar: theme.mixins.toolbar,

}));

// app bar

const BaseRouter: FC = () => {

	const classes = useStyles();

	const dispatch = useDispatch();
	const inputs = { brand_sub_domain: '', sub_domain_auth: false };
	const [data, setData] = useState({ ...inputs });
	const [errors, setErrors] = useState({ ...inputs, sub_domain_auth: '' });
	const [action, setAction] = useState({
		isProcessing: false,
		isSubmitted: false,
	});
	const [brandModalOpen, setBrandModalOpen] = useState(false);
	const [isBrandPortal, setIsBrandPortal] = useState(false);
	const [initializing, setInitializing] = useState(true);

	useEffect(() => {
		if (CookieServices.get('accessToken')) {
			if (window.location.pathname === '/') navigate('/home');
			getUser();
			// @ts-ignore
			dispatch(SettingsAction.getLoginSettings()).then((response) => {
				document.title = response.data.company_name;
			});
			setInitializing(false);

		} else {
			if (window.location.host.split('.')[0]) {
				let subdomains = window.location.host.split('.');
				if ((subdomains[0] === 'signup-brand') || (subdomains[0] === 'localhost:3000')) {
					setIsBrandPortal(false);
					setInitializing(false);
				} else {
					setIsBrandPortal(true);
					setInitializing(false);
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getUser = () => {
		// @ts-ignore
		dispatch(UserAction.getUser()).then(
			(response: { status: number; data: any }) => {
				if (response.status === 1) {
					const business = response.data;
					if (business.brand_sub_domain === '' && business.status === 'active') {
						setBrandModalOpen(true);
					} else {
						setBrandModalOpen(false);
					}
				}
			}
		);
	};

	const handleChange = (e: any) => {
		const { name, value } = handleInputChange(e);
		// @ts-ignore
		setErrors({ ...errors, ...validateSubDomain(name, value, data) });
		// @ts-ignore
		data[name] = value;
		setData({ ...data });
	};

	const handleSubmit = (e: { preventDefault: () => void }) => {
		e.preventDefault();
		setAction({ ...action, isSubmitted: true, isProcessing: true });
		const formErrors = {};
		for (const [name, value] of Object.entries(data)) {
			const inputError = validateSubDomain(name, value, data);
			// @ts-ignore
			formErrors[name] = inputError[name];
		}
		let valid = validateForm(formErrors);
		if (!valid) {
			// @ts-ignore
			setErrors(formErrors);
			setAction({ ...action, isSubmitted: true, isProcessing: false });
			return;
		}
		dispatchUpdateSubdomainAction();
	};

	const dispatchUpdateSubdomainAction = () => {
		// @ts-ignore
		dispatch(SettingsAction.updateSubDomain(data)).then(
			(response: {
				status: number;
				errors: {
					sub_domain_auth: string;
					brand_sub_domain: string;
				};
			}) => {
				if (response.status === 1) {
					setAction({ isSubmitted: false, isProcessing: false });
					setErrors({ ...response.errors });
					setBrandModalOpen(false);
				} else {
					setErrors({ ...response.errors });
					setAction({ isSubmitted: true, isProcessing: false });
				}
			}
		);
	};

	const logoutAction = () => {
		UserAction.logout();
		setBrandModalOpen(false);
		navigate('insta-login');
		window.location.reload();
	};


	return (
		<Box>
			{!initializing && (
				<React.Fragment>
					<Router>
						<Routes>
							<Box style={{ display: `flex`, height: `100vh`, width: `100vw` }}>
								<Routes>
									<Route path={'/create-account/:approvalKey'} element={<InstaCreateAccount />}></Route>
									<Route path={'/payment-preference/:approvalKey'} element={<InstaPaymentPreference />}></Route>
									<Route path={'/signin'} element={<SignIn />}></Route>
									<Route path={'/forgot-password'} element={<ForgotPassword />}></Route>
									<Route path={'/signup'} element={<SignUp />} />
									<Route path={'/insta-login'} element={<InstaSignIn />}></Route>
									{!CookieServices.get('accessToken') && (!isBrandPortal) && (<Navigate to={'/insta-login'} />)}
									{!CookieServices.get('accessToken') && (isBrandPortal) && (<Navigate to={'/insta-login'} />)}
									<Route path={'/privacy-policy'} element={<PrivacyPolicy />}></Route>
									<Route path={'/terms-of-service'} element={<Tos />}></Route>
								</Routes>
							</Box>
							<Route path={'/approve/:id/:key'} element={<ApproveBusiness />} />
							{CookieServices.get('accessToken') && (
								<Box className={classes.appContainer}>
									<NsDrawerNavigation />
									<Box >
										{/* app bar */}
										{/* <div className={classes.toolbar} /> */}
										{/* app bar */}


										<Routes>
											<Route path={'/home'} element={<Home />} />
											<Route path={'qr-code/generate'} element={<GenerateQrCode brand_sub_domain={null} />}></Route>
											<Route path={'coupons/'} element={<Coupons />}></Route>
											<Route path={'deals/'} element={<Deal />}></Route>
											<Route path={'deals/add'} element={<AddUpdateDeal />}></Route>
											<Route path={'deals/edit/:id'} element={<AddUpdateDeal />}></Route>
											<Route path={'settings/*'} element={<Settings />}></Route>
											<Route path={'profile/*'} element={<Profile />}></Route>
											<Route path={'winners/participants/:id'} element={<GiveawayParticipants />}></Route>
											<Route path={'winners/*'} element={<Winners />}></Route>
											<Route path={'shoppers/:id'} element={<Shopper />}></Route>
											<Route path={'shoppers/*'} element={<Shoppers />}></Route>
											<Route path={'ig-insights/'} element={<IgInsights />}></Route>
											<Route path={'ig-insights/chart'} element={<InsightChart />}></Route>
											<Route path={'incentive-tools/'} element={<IncentiveTools />}></Route>
											<Route path={'incentive-tools/qr-code'} element={<IncentiveQrCode />}></Route>
											{/* <Route path={'stories/'} element={<Stories />}></Route> */}
											<Route path={'payments/*'} element={<Payments />}></Route>
											<Route path={'invoices/*'} element={<Invoices />}></Route>
											<Route path={'contact-support/'} element={<ContactSupport />}></Route>
										</Routes>
									</Box>
								</Box>
							)}
						</Routes>
					</Router>
					<Modal
						show={brandModalOpen}
						// @ts-ignore
						size={`md`}
						aria-labelledby='contained-modal-title-vcenter'
						centered
						backdrop='static'
					>
						<Modal.Header>
							<Modal.Title id='contained-modal-title-vcenter'>
								Configure Your Subdomain
							</Modal.Title>
						</Modal.Header>
						<form onSubmit={handleSubmit} noValidate>
							<Modal.Body>
								{/* @ts-ignore */}
								<FormGroupInput
									append='after'
									ig_text={`.${process.env.REACT_APP_DOMAIN}`}
									label='Subdomain name'
									name='brand_sub_domain'
									tabIndex='1'
									error={
										action.isSubmitted && errors.brand_sub_domain
											? errors.brand_sub_domain
											: ''
									}
									placeholder='Enter subdomain name'
									value={data.brand_sub_domain}
									onChange={handleChange}
									maskType={`alphanumeric-with-hiphen-small-only`}
									onPaste={(e: any) => {
										e.preventDefault()
										return false;
									}}
								/>
								{/* @ts-ignore */}
								<FormCheckbox
									label='I authorize Guru Club to create the subdomain'
									name='sub_domain_auth'
									tabIndex='2'
									checked={data.sub_domain_auth}
									error={
										action.isSubmitted && errors.sub_domain_auth
											? errors.sub_domain_auth
											: ''
									}
									onChange={handleChange}
									className=''
								/>
							</Modal.Body>
							<Modal.Footer>
								<button
									className='mr-auto btn btn-outline-danger btn-icon icon-right'
									onClick={logoutAction}
								>
									Cancel
								</button>
								<button type="submit" className={`btn btn-sm  btn-outline-primary btn-icon icon-right mr-1`} disabled={action.isProcessing} >{(action.isProcessing) ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : 'Save'}</button>
							</Modal.Footer>
						</form>
					</Modal>
				</React.Fragment>
			)
			}
			{initializing && <LoadingIndicator />}
		</Box >
	);
};

export default BaseRouter;
