import React from 'react'
import { connect } from 'react-redux';
import { ShoppersAction } from '../../../_redux/actions';
import { SummaryWidget } from '../../../_components/summary-widget';

function TotalShoppers({ isLoading, total_shoppers }) {

    return <SummaryWidget loading={isLoading} text={'Total Shoppers'} value={total_shoppers} bgColor={"#0ec164"} color={"#ffffff"} />
}


function mapStateToProps(state) {
    return {
        isLoading: state.ShoppersReducer.total_shoppers_loading,
        total_shoppers: state.ShoppersReducer.total_shoppers,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTotalShoppers: dispatch(ShoppersAction.getTotalShoppers())
    }
};

const connectedCoupon = (connect(mapStateToProps, mapDispatchToProps)(TotalShoppers));
export { connectedCoupon as TotalShoppers };
