import React, { useState } from 'react'
import { makeStyles, Grid, Box, Avatar, Typography, Button, Paper } from '@material-ui/core'
import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../_components/modal'
import { FormDateRangePicker } from '../../../_components/form-input'
import { handleInputChange, validateForm } from '../../../_helpers'
import { GiveawayWinnerAction } from '../../../_redux/actions'
import { connect, useDispatch } from 'react-redux'
import { CardHeader, CardContent } from './../../../_components/card'
import { DateRange, Email, Instagram, People, Schedule } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10)
    },
    latestWinnerBox: {
        borderLeft: `1px solid #eee`,
    },
    generateWinnerBox: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5)
    },
    helperText: {
        fontSize: "12px",
        paddingTop: "5px"
    },
}))

const inputs = { giveaway_date: '' }

const GenerateWinner = ({ isLoading, latestWinner }) => {

    const classes = useStyles()
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const [contestants, setContestants] = useState({ isVisibile: false, count: 0 })
    const [loading, setLoading] = useState(false);


    const onHide = () => {
        setShowModal(false);
    }

    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    const handleChange = (e) => {

        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        if (value !== '') {
            getContestantCount(value);
        } else {
            setContestants({ isVisibile: false, count: 0 })
        }
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};

        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        generateWinner();
    }

    const handleReset = () => {
        setShowModal(false);
        setData({ ...inputs });
    }


    const validate = (name, value) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'giveaway_date': {
                errors.giveaway_date = '';
                if (value === '')
                    errors.giveaway_date = 'Date range is required. ';
                break;
            } default: {
                errors[name] = '';
                break;
            }
        }
        return errors;
    }

    const getContestantCount = (dateTime) => {
        setLoading(true);
        dispatch(GiveawayWinnerAction.getWinnerCount({ giveaway_between: dateTime }))
            .then((response) => {
                setLoading(false);
                if (response.status === 1) {
                    setContestants({ isVisibile: true, count: response.data.contestants })
                } else {
                    setContestants({ isVisibile: false, count: 0 })
                }
            });
    }

    const generateWinner = () => {
        setLoading(true);
        dispatch(GiveawayWinnerAction.generateWinner({ giveaway_between: data.giveaway_date }))
            .then((response) => {
                setLoading(false);
                if (response.status === 1) {
                    setShowModal(false);
                    setAction({ isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors })
                } else {
                    setAction({ isSubmitted: true, isProcessing: false });
                    setErrors({ ...response.errors })
                }
            });
    }

    return (
        <React.Fragment>
            <Paper>
                <Grid container spacing={1}>

                    {/* BEGIN: Generate winner section */}
                    <Grid item xs={12} sm={12} lg={6} md={6} xl={6}>
                        <Box>
                            <CardHeader title="Giveaways" />
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} >
                                        <Button variant="contained" color="primary" onClick={() => { setData({ ...inputs }); setContestants({ isVisibile: false, count: 0 }); setShowModal(true) }}>Generate Giveaway Winner</Button>
                                        <Typography variant="caption" component="p" className={`${classes.helperText}`}>Click the above button to generate the giveaway winner.</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Box>
                    </Grid>
                    {/* BEGIN: Generate winner section */}


                    {!isLoading && latestWinner && (Object.keys(latestWinner).length > 0) && (
                        <Grid item xs={12} sm={12} lg={6} md={6} xl={6} >
                            <Box className={classes.latestWinnerBox}>
                                <CardHeader title="Latest Winner" />
                                <CardContent>
                                    <Box style={{ display: 'flex', paddingBottom: `15px` }}>
                                        <Grid container spacing={1} direction="row" justifyContent="flex-start" alignItems="center">
                                            <Grid item >
                                                <Avatar alt={latestWinner.name} src={latestWinner.profile_image} style={{ width: 75, height: 75 }} />
                                            </Grid>
                                            <Grid item  >
                                                <Box style={{ paddingLeft: `15px` }}>
                                                    <Typography variant="h6" color="initial">{latestWinner.instagram.name}</Typography>
                                                    <Typography variant="caption" component="div" color="initial"><Instagram style={{ fontSize: `14px` }} /> {`@${latestWinner.insta_handle}`}</Typography>
                                                    <Typography variant="caption" component="div" color="initial"><Email style={{ fontSize: `14px` }} /> {latestWinner.email}</Typography>
                                                    <Typography variant="caption" component="div" color="initial"><People style={{ fontSize: `14px` }} />&nbsp;{latestWinner.followers_count} Followers</Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box style={{ display: 'flex' }}>
                                        <Typography variant="caption" display="inline"  ><DateRange style={{ fontSize: `14px` }} /> Date Range: {latestWinner.give_away_date}</Typography>
                                        <Typography variant="caption" display="inline" style={{ paddingLeft: `10px` }} ><Schedule style={{ fontSize: `14px` }} /> Drawn On: {latestWinner.created_at}</Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Paper>

            <ConfzModal isModalOpen={showModal} onHide={onHide} size="md" loader={loading}>
                <form onSubmit={handleSubmit} noValidate>
                    <ConfzModalHeader id="importResult" title="Generate Giveaway Winner" onHide={onHide} />
                    <ConfzModalBody>
                        <FormDateRangePicker
                            name="giveaway_date"
                            placeholder="Select Date Range"
                            value={data.giveaway_date}
                            label="Select give away Date Range"
                            onApply={handleChange}
                            error={(action.isSubmitted && (errors.giveaway_date)) ? (errors.giveaway_date) : ''}
                            className={`m-0`}
                        />
                        {contestants.isVisibile && contestants.count === 0 && (<p style={{ padding: `5px`, fontSize: `12px`, marginBottom: 0 }}>No contestants for this date range.</p>)}
                        {contestants.isVisibile && contestants.count === 1 && (<p style={{ padding: `5px`, fontSize: `12px`, marginBottom: 0 }}>Only one contestant found.</p>)}
                        {contestants.isVisibile && contestants.count > 1 && (<p style={{ padding: `5px`, fontSize: `12px`, marginBottom: 0 }}>The winner will be drawn at random from {contestants.count} contestants.</p>)}
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="3" onHide={handleReset} text="Reset" />
                        <ConfzModalFooterSaveBtn tabIndex="2" isProcessing={action.isProcessing} text="Generate" disabled={!contestants.isVisibile || contestants.count === 0} />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>
        </React.Fragment>
    )
}




function mapStateToProps(state) {
    return {
        isLoading: state.GiveawayWinnerReducer.latest_winner_loading,
        latestWinner: state.GiveawayWinnerReducer.latest_winner,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLatestWinner: dispatch(GiveawayWinnerAction.getLatestWinner())
    }
};

const connectedGenerateWinner = connect(mapStateToProps, mapDispatchToProps)(GenerateWinner);
export { connectedGenerateWinner as GenerateWinner };
