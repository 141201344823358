import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import { InstaPaymentAction, SignUpAction } from '../../../_redux/actions';
import { FormCheckbox, FormInput, FormPhoneInputNew } from '../../../_components/form-input';
import Config from '../../../_helpers/config';
import { handleInputChange, validateForm } from '../../../_helpers';
import validateSignUp from '../insta-create-account/validateInstaCreateAccount';

import { paperElevation } from '../../../config/config';
import Paper from '@material-ui/core/Paper';
import colors from '../../../theme/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import GuruLogo from '../../../components/GuruLogo';
import loginBackgroundImage from './../../../images/login_background.jpg';
import { CircularProgress, Grid } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { DefaultPayment } from './default-payment/DefaultPayment';
import { CreditCardDetails } from './credit-card/CreditCardDetails';
import { BankDetails } from './bank-details/BankDetails';
import { FinishRegisteration } from './finish/FinishRegisteration';

function InstaPaymentPreference({ ...props }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading } = useSelector((state) => state.InstaPaymentReducer);

    const getPaymentDetails = (params) => dispatch(InstaPaymentAction.getPaymentDetails(params));

    const { approvalKey } = useParams();

    const useStyles = makeStyles((theme) => ({
        root: {
            // backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: 30,
            maxWidth: 700,
            minWidth: 700,
            [theme.breakpoints.down('sm')]: {
                minWidth: 300,
                maxWidth: 300,
            },
        },
        title: {
            marginBottom: 40,
            // color: theme.palette.common.white,
        },
        // textField: {
        //     '& .MuiFormLabel-root': {
        //         color: theme.palette.common.white,
        //     },
        //     '& .MuiOutlinedInput-root': {
        //         '& fieldset': {
        //             borderColor: 'white',
        //             color: theme.palette.common.white,
        //         },
        //         '&.Mui-focused fieldset': {
        //             borderColor: colors.secondary,
        //             color: theme.palette.common.white,
        //         },
        //         '&:hover fieldset': {
        //             borderColor: colors.secondary,
        //             color: theme.palette.common.white,
        //         },
        //         color: theme.palette.common.white,
        //     },
        //     marginBottom: 15,
        // },
        // whiteColor: {
        //     color: theme.palette.common.white,
        // },
        // loginBtn: {
        //     marginTop: 40,
        //     marginBottom: 20,
        //     backgroundColor: colors.secondary,
        // },
        // link: {
        //     color: 'white',
        //     cursor: 'pointer',
        // },
        signUpContainer: {
            backgroundImage: `url(${loginBackgroundImage})`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            backgroundRepeat: `no-repeat`,
            width: '100%',
            display: 'flex',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'center',
        }
    }));

    const styles = useStyles();

    useEffect(() => {
        document.title = `${Config.app_name} | Payment Preference`;

        getPaymentDetails(approvalKey);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approvalKey])


    return (
        <div className={`flex-column form-sm full-width full-height justify-center align-items-center ${styles.signUpContainer}`}>
            <Paper classes={{ root: styles.root }} elevation={paperElevation}>
                <GuruLogo />
                <Typography
                    color={'primary'}
                    classes={{ root: styles.title }}
                    variant={'h4'}
                >
                    Payment Preference
                </Typography>

                {loading &&
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: `150px` }}>
                        <Grid item >
                            <CircularProgress color="secondary" />
                        </Grid>
                    </Grid>
                }
                {!loading &&
                    <React.Fragment>
                        <DefaultPayment />
                        <CreditCardDetails />
                        <BankDetails />
                        <FinishRegisteration />
                    </React.Fragment>
                }

            </Paper>
        </div>
    )
}



const connectedSignUp = connect(null)(InstaPaymentPreference);
export { connectedSignUp as InstaPaymentPreference };
