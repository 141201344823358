import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SummaryWidget } from '../../../_components/summary-widget';

function ProfileViewsCount({ isLoading, profile_views }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=profile_views`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily Profile Views'} value={profile_views} bgColor={"#a852f7"} color={"#ffffff"} />
        </Box>
    );

}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedProfileViewsCount = (connect(mapStateToProps, mapDispatchToProps)(ProfileViewsCount));
export { connectedProfileViewsCount as ProfileViewsCount };
