import { Box, CardContent, Grid, Link, makeStyles, styled, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react'
import { GenerateQrCode } from '../../home';


// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components

// Styled component for the left grid 

export const ComponentToPrint = React.forwardRef((props, ref) => {
    const { latest_deal } = props;

    const getPageMargins = () => {
        return `@page { margin: 0px }`;
    };

    const useStyles = makeStyles((theme) => ({
        root: {
            // backgroundColor: theme.palette.primary.main,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '816px',
                height: '520px',
            },
        },
    }));

    const styles = useStyles();

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (

        <Box ref={ref} className={`flyer-size ${styles.root}`}>
            <style>{getPageMargins()}</style>
            <CardContent style={{ padding: "0px" }}>
                <Box className={`flyer-size ${styles.root}`}>
                    <Grid container spacing={0} display="flex" alignItems="center" justifyContent={`space-between`}>
                        <Grid item display="flex" justifyContent="center" alignItems="center" style={{ maxWidth: `519px`, width: `63.61%` }}>
                            <Box>
                                <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: 4, maxWidth: `520px` }} >
                                    <Link underline={'none'} fontSize={`14px`} target="_blank" rel="noreferrer" {...props}>
                                        <img id='flyer-logo' data-html2canvas-ignore={true} src={latest_deal.logo} alt={latest_deal.logo} style={{ maxWidth: `${isSmallScreen ? '90px' : '200px'}`, height: `unset` }} />
                                        <img id='flyer-logo-hidden' src={latest_deal.logo} alt={latest_deal.logo} style={{ maxWidth: 200, display: 'none', height: `unset` }} />
                                    </Link>
                                </Box>
                                <Typography variant="body1" align="center" mt={1} style={{ wordBreak: `break-word` }} dangerouslySetInnerHTML={{ __html: latest_deal.reward_description }} className={`para-0`} />
                                <Box mt={2}>
                                    <Grid container spacing={1} direction="row" alignItems="center">
                                        <Grid item xs={12} sx={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box textAlign="center" data-html2canvas-ignore={true} id='flyer-qr'>
                                                <GenerateQrCode hideAction={true} hideTitle={true} qrSize={isSmallScreen ? '90' : '200'}/>
                                                <Typography variant="caption" align="center" style={{ fontSize: `11px` }} mt={5} >Scan for Coupon and Terms</Typography>
                                            </Box>
                                            <Box textAlign="center" id='flyer-qr-hidden' style={{display: 'none'}}>
                                                <GenerateQrCode hideAction={true} hideTitle={true} qrSize={200} />
                                                <Typography variant="caption" align="center" style={{ fontSize: `11px` }} mt={5} >Scan for Coupon and Terms</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item display="flex" justifyContent="center" alignItems="center" style={{ width: `36.39%` }}>
                            <Grid container spacing={0} style={{ height: `100%` }}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display="flex" alignItems="center" alignContent="center" justifyContent="center" >
                                    <img src={latest_deal.sample_story} alt={latest_deal.sample_story} width="100%" className='story_image' style={{ maxWidth: `297px` }} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
        </Box>
    );
});


