import { Avatar, Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Card, CardContent, CardHeader } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

function LatestWinner({ loading, latestWinner }) {

    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                title="Giveaway Winners"
                titleTypographyProps={{ variant: 'h4', component: 'h4' }}
            />
            <CardContent>
                {loading && (
                    <div className="row">
                        <div className="col-md-12 d-flex align-items-center justify-content-center p-5">

                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                )}
                {!loading && latestWinner && (Object.keys(latestWinner).length > 0) && (
                    <React.Fragment>
                        <Grid container spacing="2" alignItems="center">
                            <Grid item >
                                <Box boxShadow="3">
                                    <Avatar src={latestWinner.profile_image} variant="square" className={classes.large} ></Avatar>
                                </Box>
                            </Grid>
                            <Grid item>
                                <h4>{latestWinner.instagram.name}</h4>
                                <h6 style={{ fontWeight: 400 }}><i className="fab fa-instagram" aria-hidden="true"></i>{`@${latestWinner.insta_handle}`}</h6>
                                <h6 style={{ fontWeight: 400 }}><i className="fa fa-envelope" aria-hidden="true"></i> {latestWinner.email}</h6>
                            </Grid>
                        </Grid>
                        <Typography variant="caption" display="block" mb gutterBottom>Date Range: {latestWinner.give_away_date}</Typography>
                        <Typography variant="caption" display="block" gutterBottom>Drawn On: {latestWinner.created_at}</Typography>
                        <NavLink to={'/winners'} className="btn btn-block text-left " style={{ color: `blue` }}><i className="fa fa-arrow-right"></i> &nbsp; {`View all winners`}</NavLink>
                    </React.Fragment>
                )}
            </CardContent>
        </Card>
    )
}



function mapStateToProps(state) {
    return {
        loading: state.GiveawayWinnerReducer.latest_winner_loading,
        latestWinner: state.GiveawayWinnerReducer.latest_winner,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        // getAllWinners: () => dispatch(GiveawayWinnerAction.getAllWinners()),
    }
};

const connectedLatestWinner = connect(mapStateToProps, mapDispatchToProps)(LatestWinner);
export { connectedLatestWinner as LatestWinner };


