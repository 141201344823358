import React from 'react'

import { Grid, Typography, Link, CardMedia, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles';

import { connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    logoCardMedia: {
        maxWidth: `200px`,
        width: `100%`
    },
    sampleStoryCardMedia: {
        maxWidth: `200px`,
        width: `100%`
    },
    colorBox: {
        border: `1px solid #cfd8dc`,
        width: `25px`,
        height: `20px`
    },
    dealDetailsBox: {
        padding: theme.spacing(3),
    }
}));

function TimeBetweenPost({ tbp }) {

    if (tbp === 'One Time')
        return (<Typography variant="subtitle1" color="initial"> {'One Time'}</Typography>)
    else if (tbp === '1 Day')
        return (<Typography variant="subtitle1" color="initial"> {'Once a day'}</Typography>)
    else if (tbp === '1 Week')
        return (<Typography variant="subtitle1" color="initial"> {'Once a week'}</Typography>)
    else if (tbp === '1 Month')
        return (<Typography variant="subtitle1" color="initial"> {'Once a month'}</Typography>)
};

function DealDetails({ latest_deal, business, ...props }) {

    const classes = useStyles();

    return (
        <Box className={`${classes.dealDetailsBox}`}>
            <Grid container spacing={2} className="deals-details-box">
                <Grid item xs={5} md={5}>
                    <Typography variant="subtitle1">Brand Instagram Handle</Typography>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Link href={`https://www.instagram.com/${latest_deal.insta_handle}`} variant="body2" target="_blank" rel="noreferrer">
                        <Typography variant="subtitle1">{`@${latest_deal.insta_handle}`}</Typography>
                    </Link>
                </Grid>
                {latest_deal.logo && (
                    <React.Fragment>
                        <Grid item xs={5} md={5}>
                            <Typography variant="subtitle1" color="initial">Logo</Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <Link href={`${business.company_url}`} variant="body2" target="_blank" rel="noreferrer">
                                <CardMedia src={latest_deal.logo} component="img" width="100" alt={`${business.company_name}`} className={classes.logoCardMedia} />
                            </Link>
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={5} md={5}>
                    <Typography variant="subtitle1" color="initial">Background Color</Typography>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Box className={classes.colorBox} style={{ backgroundColor: `${(latest_deal.primary_color_hex) ? (latest_deal.primary_color_hex) : ''}` }}> </Box>
                </Grid>
                <Grid item xs={5} md={5}>
                    <Typography variant="subtitle1" color="initial">Text Color</Typography>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Box className={classes.colorBox} style={{ backgroundColor: `${(latest_deal.secondary_color_hex) ? (latest_deal.secondary_color_hex) : ''}` }}> </Box>
                </Grid>
                <Grid item xs={5} md={5}>
                    <Typography variant="subtitle1" color="initial">Button Color</Typography>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Box className={classes.colorBox} style={{ backgroundColor: `${(latest_deal.link_color_hex) ? (latest_deal.link_color_hex) : ''}` }}> </Box>
                </Grid>
                <Grid item xs={5} md={5}>
                    <Typography variant="subtitle1" color="initial">Is this a giveaway?</Typography>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Typography variant="subtitle1" color="initial">{latest_deal.is_giveaway ? 'Yes' : 'No'}</Typography>
                </Grid>
                {latest_deal.times_between_post && (
                    <React.Fragment>
                        <Grid item xs={5} md={5}>
                            <Typography variant="subtitle1" color="initial">How Often A User Can Redeem</Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <TimeBetweenPost tbp={latest_deal.times_between_post} />
                        </Grid>
                    </React.Fragment>
                )}
                {/* <Grid item xs={5} md={5}>
                    <Typography variant="subtitle1" color="initial">Font</Typography>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Typography variant="subtitle1" color="initial">{latest_deal.font}</Typography>
                </Grid> */}
                {/* {latest_deal.headline &&
                    <React.Fragment>
                        <Grid item xs={5} md={5}>
                            <Typography variant="subtitle1" color="initial">Headline</Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <Typography variant="subtitle1" color="initial" style={{ wordBreak: `break-word` }} dangerouslySetInnerHTML={{ __html: (latest_deal.headline) }} />
                        </Grid>
                    </React.Fragment>
                } */}
                <Grid item xs={5} md={5}>
                    <Typography variant="subtitle1" color="initial">Description</Typography>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Typography variant="subtitle1" color="initial" style={{ wordBreak: `break-word` }} dangerouslySetInnerHTML={{ __html: latest_deal.reward_description }} />
                </Grid>
                <Grid item xs={5} md={5}>
                    <Typography variant="subtitle1" color="initial">Fine Print</Typography>
                </Grid>
                <Grid item xs={7} md={7}>
                    <Typography variant="subtitle1" color="initial" style={{ wordBreak: `break-word` }} dangerouslySetInnerHTML={{ __html: latest_deal.offer_description }} />
                </Grid>
                {latest_deal.require && (
                    <React.Fragment>
                        <Grid item xs={5} md={5}>
                            <Typography variant="subtitle1" color="initial">Require</Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <Typography variant="subtitle1" color="initial">{latest_deal.require.join(', ')}</Typography>
                        </Grid>
                    </React.Fragment>
                )}

                {latest_deal.hashtag && (
                    <React.Fragment>
                        <Grid item xs={5} md={5}>
                            <Typography variant="subtitle1" color="initial">Suggested Hashtag</Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <Typography variant="subtitle1" color="initial">{`#${latest_deal.hashtag}`}</Typography>
                        </Grid>
                    </React.Fragment>
                )}
                {latest_deal.giveaway_date_fmt && (
                    <React.Fragment>
                        <Grid item xs={5} md={5}>
                            <Typography variant="subtitle1" color="initial">Giveaway ends in</Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <Typography variant="subtitle1" color="initial">{`${latest_deal.giveaway_date_fmt}`}</Typography>
                        </Grid>
                    </React.Fragment>
                )}
                {latest_deal.coupon_redemption_url && (
                    <React.Fragment>
                        <Grid item xs={5} md={5}>
                            <Typography variant="subtitle1" color="initial">Coupon Redemption URL</Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <Typography variant="subtitle1" color="initial" style={{ wordWrap: 'break-word' }}>{latest_deal.coupon_redemption_url}</Typography>
                        </Grid>
                    </React.Fragment>
                )}
                {latest_deal.sample_story && (
                    <React.Fragment>
                        <Grid item xs={5} md={5}>
                            <Typography variant="subtitle1" color="initial">Sample Story</Typography>
                        </Grid>
                        <Grid item xs={7} md={7}>
                            <Link href={`${business.company_url}`} variant="body2" target="_blank" rel="noreferrer">
                                <CardMedia src={latest_deal.sample_story} component="img" width="100" alt={`${business.company_name}`} className={classes.sampleStoryCardMedia} />
                            </Link>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Box>
    )
}



function mapStateToProps(state) {
    return {
        business: state.UserReducer.user
    };
}

const connectedDealDetails = (connect(mapStateToProps, null)(DealDetails));
export { connectedDealDetails as DealDetails };


