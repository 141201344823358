import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, } from '@material-ui/core'
import { AccountBalance } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'

import { ConfzModal, ConfzModalBody, ConfzModalFooter, ConfzModalFooterCloseBtn, ConfzModalFooterSaveBtn, ConfzModalHeader } from '../../../../_components/modal'

import { handleInputChange, validateForm } from '../../../../_helpers'
import { FormInput, FormSelect, FormPhoneInputNew } from '../../../../_components/form-input'
import { Grid, makeStyles, Divider } from "@material-ui/core";
import validateAddUpdateBankDetails from './validateAddUpdateBankDetails'

import { InstaPaymentAction } from '../../../../_redux/actions'

import { connect } from 'react-redux';
import { FormLabel } from 'react-bootstrap'
import { ErrorAlert } from '../../../../_components/alert'
import { useParams } from 'react-router-dom'

const countries = require('../country-list.json')



const useStyles = makeStyles((theme) => ({
    bankDetailsGrid: {
        paddingBottom: theme.spacing(2)
    }
}));


function AddUpdateBankDetails({ user, addUpdateBankDetails, getPaymentDetails, props }) {

    const [show, setShow] = useState(false);

    const classes = useStyles();
    const { approvalKey } = useParams();
    const inputs = { account_holder_name: '', routing_number: '', confirm_routing_number: '', account_number: '', confirm_account_number: '', account_holder_type: 'individual', name: '', address_line1: '', address_line2: '', city: '', state: '', postal_code: '', country: 'US', email: '', phone: '', approval_key: approvalKey }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, country: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const [responseMessage, setResponseMessage] = useState(null)

    useEffect(() => {
        setData({
            ...inputs,
            name: user.name,
            card_holder: user.name,
            email: user.email,
            phone: user.company_phone,
            country: ((user.billing_details) ? (user.billing_details.address.country) : 'US'),
            address_line1: ((user.billing_details) ? (user.billing_details.address.line1) : ''),
            address_line2: ((user.billing_details) ? (user.billing_details.address.line2) : ''),
            city: ((user.billing_details) ? (user.billing_details.address.city) : ''),
            postal_code: ((user.billing_details) ? (user.billing_details.address.postal_code) : ''),
            state: ((user.billing_details) ? (user.billing_details.address.state) : ''),
            approval_key: approvalKey
        })
    }, [user, approvalKey])


    /**
    * function description
    * @param Null
    * @return view
    * @author Akshay N
    * @created_at 
    */
    const handleChange = (e) => {
        setResponseMessage(null);
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateAddUpdateBankDetails(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateAddUpdateBankDetails(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        formData.append("account_holder_name", data.account_holder_name);
        formData.append("routing_number", data.routing_number);
        formData.append('account_number', data.account_number);
        formData.append("account_holder_type", data.account_holder_type);
        formData.append("name", data.name);
        formData.append('address_line1', data.address_line1);
        formData.append('address_line2', data.address_line2);
        formData.append('city', data.city);
        formData.append('state', data.state);
        formData.append('postal_code', data.postal_code);
        formData.append('country', data.country);
        formData.append('phone', data.phone);
        formData.append('approval_key', approvalKey);
        dispatchAddUpdateBankDetails(data);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const dispatchAddUpdateBankDetails = (formData) => {
        addUpdateBankDetails(formData).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setResponseMessage(null);
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                getPaymentDetails(approvalKey);
            } else {
                setResponseMessage(response.message);
                setAction({ isSubmitted: true, isProcessing: false });
                setErrors({ ...response.errors })
            }
        });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const addUpdateCardModal = (e) => {
        e.preventDefault();
        setData({
            ...inputs,
            name: user.name,
            card_holder: user.name,
            email: user.email,
            phone: user.company_phone,
            country: ((user.billing_details) ? (user.billing_details.address.country) : 'US'),
            address_line1: ((user.billing_details) ? (user.billing_details.address.line1) : ''),
            address_line2: ((user.billing_details) ? (user.billing_details.address.line2) : ''),
            city: ((user.billing_details) ? (user.billing_details.address.city) : ''),
            postal_code: ((user.billing_details) ? (user.billing_details.address.postal_code) : ''),
            state: ((user.billing_details) ? (user.billing_details.address.state) : '')
        });
        setShow(true);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const onHide = () => {
        setShow(false);
        setErrors({});
        getPaymentDetails(approvalKey);
    }

    return (
        <Box>

            <ConfzModal isModalOpen={show} size={`lg`} onHide={onHide} className={`AddUpdateBankDetails`}>
                <form onSubmit={handleSubmit} noValidate >
                    <ConfzModalHeader id="importCoupon" title="Add Bank Account Details" onHide={onHide} />
                    <ConfzModalBody>
                        <Grid container spacing={2} className={classes.bankDetailsGrid}>
                            {responseMessage && <ErrorAlert message={responseMessage} />}
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Account Holder Name"
                                    placeholder="Enter account holder name"
                                    name="account_holder_name"
                                    tabIndex="1"
                                    value={data.account_holder_name}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.account_holder_name)) ? (errors.account_holder_name) : ''}
                                    className="col-md-12"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Bank Account Type</FormLabel>
                                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                        <FormControlLabel value="individual" onChange={handleChange} name={'account_holder_type'} checked={data.account_holder_type === 'individual'} control={<Radio color="primary" />} label="Personal" />
                                        <FormControlLabel value="company" onChange={handleChange} name={'account_holder_type'} checked={data.account_holder_type === 'company'} control={<Radio color="primary" />} label="Business" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Routing Number"
                                    placeholder="Enter routing number"
                                    name="routing_number"
                                    tabIndex="2"
                                    value={data.routing_number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.routing_number)) ? (errors.routing_number) : ''}
                                    maxLength={9}
                                    className="col-md-12"
                                    maskType={'digit-only'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Confirm Routing Number"
                                    placeholder="Re-enter routing number"
                                    name="confirm_routing_number"
                                    tabIndex="3"
                                    value={data.confirm_routing_number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.confirm_routing_number)) ? (errors.confirm_routing_number) : ''}
                                    maxLength={9}
                                    className="col-md-12"
                                    maskType={'digit-only'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Account Number"
                                    placeholder="Enter account number"
                                    name="account_number"
                                    tabIndex="4"
                                    value={data.account_number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.account_number)) ? (errors.account_number) : ''}
                                    maxLength={17}
                                    className="col-md-12"
                                    maskType={'digit-only'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Confirm Account Number"
                                    placeholder="Re-enter account number"
                                    name="confirm_account_number"
                                    tabIndex="5"
                                    value={data.confirm_account_number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.confirm_account_number)) ? (errors.confirm_account_number) : ''}
                                    maxLength={17}
                                    className="col-md-12"
                                    maskType={'digit-only'}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container style={{ marginTop: `20px` }} >
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Full Name"
                                    placeholder="Enter full name"
                                    name="name"
                                    tabIndex="6"
                                    value={data.name}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                    maxLength={100}
                                    className="col-md-12"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Address Line 1"
                                    placeholder="Enter address line 1"
                                    name="address_line1"
                                    tabIndex="7"
                                    value={data.address_line1}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.address_line1)) ? (errors.address_line1) : ''}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Address Line 2"
                                    placeholder="Enter address line 2 (Optional)"
                                    name="address_line2"
                                    tabIndex="8"
                                    value={data.address_line2}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.address_line2)) ? (errors.address_line2) : ''}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="City"
                                    placeholder="Enter city"
                                    name="city"
                                    tabIndex="9"
                                    value={data.city}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.city)) ? (errors.city) : ''}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="State/Province/Region"
                                    placeholder="Enter State/Province/Region"
                                    name="state"
                                    tabIndex="10"
                                    value={data.state}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.state)) ? (errors.state) : ''}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="ZIP/Postal Code"
                                    placeholder="Enter ZIP/Postal code"
                                    name="postal_code"
                                    tabIndex="11"
                                    value={data.postal_code}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.postal_code)) ? (errors.postal_code) : ''}
                                    className="col-md-12"
                                    maskType={'digit-only'}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormSelect
                                    label="Country"
                                    placeholder="Enter country"
                                    name="country"
                                    onChange={handleChange}
                                    className="col-md-12"
                                    value={data.country}
                                    error={(action.isSubmitted && (errors.country)) ? (errors.country) : ''}
                                    data={countries}
                                    tabIndex="12"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {/* <FormPhoneInput
                                    label="Phone Number"
                                    name="phone"
                                    tabIndex="13"
                                    error={(action.isSubmitted && (errors.phone)) ? (errors.phone) : ''}
                                    placeholder="Enter phone number"
                                    value={data.phone}
                                    onChange={handleChange}
                                    className="col-md-12"
                                    maxLength={100}
                                /> */}
                                <FormPhoneInputNew
                                    label="Phone Number"
                                    name="phone"
                                    tabIndex="13"
                                    error={(action.isSubmitted && (errors.phone)) ? (errors.phone) : ''}
                                    placeholder="Enter phone number"
                                    value={data.phone}
                                    onChange={handleChange}
                                    className="col-md-12"
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Box className="col-md-12">
                                    <p>Terms and Conditions for Brands are at <a href="https://guruclub.wpengine.com/terms-of-service-brands" target="_blank" rel="noopener noreferrer">https://guruclub.wpengine.com/terms-of-service-brands</a></p>
                                </Box>
                            </Grid>
                        </Grid>
                    </ConfzModalBody>
                    <ConfzModalFooter>
                        <ConfzModalFooterCloseBtn tabIndex="15" onHide={onHide} text="Cancel" />
                        <ConfzModalFooterSaveBtn tabIndex="14" isProcessing={action.isProcessing} text="Ok" />
                    </ConfzModalFooter>
                </form>
            </ConfzModal>
            <Button variant="contained" color="primary" startIcon={<AccountBalance>Add</AccountBalance>} onClick={addUpdateCardModal}>Add Bank Details</Button>
        </Box>
    )
}




function mapStateToProps(state) {
    return {
        user: state.UserReducer.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addUpdateBankDetails: (params) => dispatch(InstaPaymentAction.addUpdateBankDetails(params)),
        getPaymentDetails: (params) => dispatch(InstaPaymentAction.getPaymentDetails(params))
    }
};

const connectedAddUpdateBankDetails = connect(mapStateToProps, mapDispatchToProps)(AddUpdateBankDetails);
export { connectedAddUpdateBankDetails as AddUpdateBankDetails };

