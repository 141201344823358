import React, { useRef } from 'react'
import { connect } from 'react-redux';
import { Box, Button, Card, CardContent, makeStyles } from '@material-ui/core'
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import { ComponentToPrint } from './ComponentToPrint';

function Flyer({ latest_deal }) {

    const componentRef = useRef();
    const pageStyle = `
        @page {
            size: 139mm 215.9mm;
        }
        @media print {
            #flyer-logo-hidden {
                display:block !important;
            }
        }

        @media print {
            #flyer-logo {
                display:none;
            }
        }

        @media print {
            #flyer-qr-hidden {
                display:block !important;
            }
        }

        @media print {
            #flyer-qr {
                display:none;
            }
        }

        @media print {
            .flyer-size {
                width:816px !important;
                height:512 !important;
            }
        }
    `;
    const handlePrint = useReactToPrint({
        pageStyle,
        content: () => componentRef.current
    });

    const handleSave = async () => {
        const element = componentRef.current;
        var canvas = document.createElement('canvas');
        canvas.width = 816;
        canvas.height = 520;

        var opts = {
            canvas : canvas,
            allowTaint: true, 
            useCORS: true,
            scale: 1,
            scrollX: -window.scrollX,
            scrollY: -window.scrollY,
            windowWidth: '1280px',
            windowHeight: element.scrollHeight,
            onclone: async function(document) {
                document.getElementById("flyer-logo-hidden").style.display = "block";
                document.getElementById("flyer-qr-hidden").style.display = "block";
            }
        };
        window.scrollTo(0, 0);
        html2canvas(element, opts).then(canvas =>{
            const data = canvas.toDataURL('image/jpg');
            const link = document.createElement('a');
    
            if (typeof link.download === 'string') {
                link.href = data;
                link.download = 'image.jpg';
    
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                window.open(data);
            }
        })

    };

    const useStyles = makeStyles((theme) => ({
        root: {
            // backgroundColor: theme.palette.primary.main,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '816px',
                height: '520px',
            },
        },
    }));

    const styles = useStyles();

    return (
        <React.Fragment>
            <Box className={styles.root}>
                <Card>
                    <CardContent style={{ padding: 0 }}>
                        <ComponentToPrint latest_deal={latest_deal} ref={componentRef} />
                    </CardContent>
                </Card>
            </Box>
            <Box display={`flex`} alignItems={`center`} justifyContent={`center`} style={{ marginTop: '25px' }}>
                <Button variant="contained" color="primary" onClick={handlePrint}>Print QR Code</Button>
                <Button variant="contained" color="primary" onClick={handleSave} style={{ marginLeft: 5 }} >Save</Button>
            </Box>
        </React.Fragment>
    )
}


function mapStateToProps(state) {
    return {
    };
}

function mapDispatchToProps(dispatch) {
    return {
    }
};

const connectedFlyer = (connect(mapStateToProps, mapDispatchToProps)(Flyer));
export { connectedFlyer as Flyer };
