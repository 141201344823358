import React from 'react'
import { connect } from 'react-redux';
import { DataTable } from './DataTable';
import { TotalReach, TotalCompletedDeals, TotalShoppers } from './';
import { Grid, Box } from '@material-ui/core'
import { PageTitle } from '../../_layouts/page-title';
import { Card, CardHeader, CardContent } from '../../_components/card';
import { ExportShoppers } from './actions/ExportShoppers';

function Shoppers() {

    return (
        <Box>

            <PageTitle title="Shoppers"
                action={
                    <React.Fragment>
                        <ExportShoppers />
                    </React.Fragment>
                }
            />

            <Grid container spacing={3} style={{ marginBottom: `15px` }}>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <TotalCompletedDeals />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <TotalReach />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <TotalShoppers />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardHeader title="Shoppers List" />
                        <CardContent>
                            <DataTable />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}


const connectedCoupon = (connect(null, null)(Shoppers));
export { connectedCoupon as Shoppers };
