import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { FormGroupInput } from '../../../_components/form-group-input';
import { FormCheckbox, FormCancelBtn, FormSaveBtn } from '../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../_helpers';
import { SettingsAction } from '../../../_redux/actions';
import { RouteConfig } from '../../../_routes'
import validateSubDomain from './validateSubDomain';

function SubDomainSettings({ brand_sub_domain }) {


    const dispatch = useDispatch();
    const inputs = { brand_sub_domain: '', sub_domain_auth: false }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, sub_domain_auth: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        setData({ brand_sub_domain: brand_sub_domain, sub_domain_auth: false })
    }, [brand_sub_domain])



    /**
   * function to handle input change
   * @param object e input object
   * @author Akshay N
   */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateSubDomain(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
    * function to handle submit action
    * @param object e input object
    * @author Akshay N
    */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateSubDomain(name, value, data);
            formErrors[name] = inputError[name];
        }
        console.log(data);
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchUpdateSubdomainAction();
    }

    /**
   * function to dispatch signup action
   * @param object e input object
   * @author Akshay N
   */
    const dispatchUpdateSubdomainAction = () => {
        dispatch(SettingsAction.updateSubDomain(data))
            .then((response) => {
                if (response.status === 1) {
                    setAction({ isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors });
                    setEdit(false);
                } else {
                    setErrors({ ...response.errors })
                    setAction({ isSubmitted: true, isProcessing: false });
                }
            });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const resetAndBack = (e) => {
        setData({ brand_sub_domain: brand_sub_domain, sub_domain_auth: false })
        setErrors({ ...inputs });
        setEdit(false);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const subDomainDetails = brand_sub_domain ?
        (

            <div className="details">
                <div className="row">
                    <div className="col-md-12">
                        Your shopper app URL:<h1><a href={(brand_sub_domain) ? (`http://${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`) : ('-')} target="_blank" rel='noopener noreferrer'> {(brand_sub_domain) ? (`http://${brand_sub_domain}.${process.env.REACT_APP_DOMAIN}`) : ('-')}</a></h1>
                    </div>
                </div>
            </div>
        ) :
        (
            <div className="details">
                <div className="row">
                    <h6 className="col-md-12" > Please generate your sub - domain</h6>
                </div>
            </div>
        );



    return (
        <React.Fragment>
            <div className=" mb-3">
                <h4 className="title">{RouteConfig('sub_domain_settings').name}
                    <span onClick={() => setEdit(!edit)} className="float-right edit-icon c-pointer"><i className="fa fa-edit"></i> Edit</span>
                </h4>
            </div>
            {edit ? (
                <React.Fragment>
                    <form onSubmit={handleSubmit} noValidate>
                        <FormGroupInput
                            append="after"
                            ig_text={`.${process.env.REACT_APP_DOMAIN}`}
                            label="Subdomain name"
                            name="brand_sub_domain"
                            tabIndex="1"
                            error={(action.isSubmitted && (errors.brand_sub_domain)) ? (errors.brand_sub_domain) : ''}
                            placeholder="Enter subdomain name"
                            value={data.brand_sub_domain}
                            onChange={handleChange}
                            maskType={`alphanumeric-with-hiphen`}
                            className="col-md-6 mr-auto"
                        />
                        <FormCheckbox
                            label="I authorize Guru Club to create the subdomain"
                            name="sub_domain_auth"
                            tabIndex="2"
                            checked={data.sub_domain_auth}
                            error={(action.isSubmitted && (errors.sub_domain_auth)) ? (errors.sub_domain_auth) : ''}
                            onChange={handleChange}
                            className="col-md-12 mr-auto"
                        />
                        <div className="col-md-12">
                            <FormSaveBtn className="mr-1" isProcessing={action.isProcessing} />
                            <FormCancelBtn onClick={resetAndBack} text="Cancel" />
                        </div>
                    </form>
                </React.Fragment>
            ) : (subDomainDetails)}
        </React.Fragment >
    )
}



function mapStateToProps(state) {
    return {
        brand_sub_domain: state.SettingsReducer.brand_sub_domain,
    };
}




const connectedSubDomainSettings = connect(mapStateToProps)(SubDomainSettings);
export { connectedSubDomainSettings as SubDomainSettings };

