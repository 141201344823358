import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormGroupInput } from '../../../_components/form-group-input';
import { FormCancelBtn, FormInput, FormPhoneInputNew, FormSaveBtn } from '../../../_components/form-input';
import { handleInputChange, validateForm } from '../../../_helpers';
import { UserAction } from '../../../_redux/actions';
import { RouteConfig } from '../../../_routes'
import validateProfileUpdate from './validateProfileUpdate';
import './style.scss'

function ProfileUpdate({ user, ...props }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inputs = { company_name: '', insta_handle: '', name: '', email: '', company_url: '', company_phone: '' }

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
    }, [user])

    useEffect(() => {
        if (isLoading) {
            setData({ company_name: user.company_name, insta_handle: user.insta_handle, name: user.name, email: user.email, company_url: user.company_url, company_phone: user.company_phone });
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    /**
    * function to handle input change
    * @param object e input object
    * @author Akshay N
    */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateProfileUpdate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
    * function to handle submit action
    * @param object e input object
    * @author Akshay N
    */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateProfileUpdate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchUpdateAction();
    }

    /**
     * function to dispatch signup action
     * @param object e input object
     * @author Akshay N
     */
    const dispatchUpdateAction = () => {
        dispatch(UserAction.updateUser(data))
            .then((response) => {
                if (response.status === 1) {
                    setAction({ isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors });
                    navigate("/profile");

                } else {
                    setErrors({ ...response.errors })
                    setAction({ isSubmitted: true, isProcessing: false });
                }
            });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const resetAndBack = (e) => {
        setData({ user });
        setErrors({ ...inputs });
        navigate("/profile");
    }


    return (
        <div className='profile-update'>
            <div className=" mb-3">
                <h4 className="title" >{RouteConfig('profile_update').name}</h4>
            </div>
            {(!isLoading) && <form onSubmit={handleSubmit} noValidate className="form-row">
                <FormInput
                    label="Company Name"
                    placeholder="Enter company name"
                    name="company_name"
                    tabIndex="1"
                    value={data.company_name}
                    onChange={handleChange}
                    error={(action.isSubmitted && (errors.company_name)) ? (errors.company_name) : ''}
                    className="col-md-6"
                />
                <FormGroupInput
                    label="Public Instagram Handle"
                    placeholder="Enter public instagram handle"
                    name="insta_handle"
                    tabIndex="2"
                    value={data.insta_handle}
                    onChange={handleChange}
                    error={(action.isSubmitted && (errors.insta_handle)) ? (errors.insta_handle) : ''}
                    ig_text={`@`}
                    className="col-md-6"
                    maskType='instagram-handle'
                    maxLength={30}
                    onPaste={(e) => { e.preventDefault(); return false; }}
                    onCopy={(e) => { e.preventDefault(); return false; }}
                />
                <FormInput
                    label="Company Representative Name"
                    placeholder="Enter company representative name"
                    name="name"
                    tabIndex="3"
                    value={data.name}
                    onChange={handleChange}
                    error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                    className="col-md-6"
                />
                <FormInput
                    label="Company Representative Email"
                    name="email"
                    type="email"
                    tabIndex="4"
                    error={(action.isSubmitted && (errors.email)) ? (errors.email) : ''}
                    placeholder="Enter company representative email"
                    value={data.email}
                    onChange={handleChange}
                    className="col-md-6"
                />
                <FormInput
                    label="Company URL"
                    name="company_url"
                    tabIndex="5"
                    error={(action.isSubmitted && (errors.company_url)) ? (errors.company_url) : ''}
                    placeholder="Enter company URL"
                    value={data.company_url}
                    onChange={handleChange}
                    className="col-md-6"
                />
                {/* <FormPhoneInput
                    label="Company Phone Number"
                    name="company_phone"
                    tabIndex="6"
                    error={(action.isSubmitted && (errors.company_phone)) ? (errors.company_phone) : ''}
                    placeholder="Enter company phone number"
                    value={data.company_phone}
                    onChange={handleChange}
                    className="col-md-6"
                /> */}
                <FormPhoneInputNew
                    label="Company Phone Number"
                    name="company_phone"
                    tabIndex="6"
                    error={(action.isSubmitted && (errors.company_phone)) ? (errors.company_phone) : ''}
                    placeholder="Enter company phone number"
                    value={data.company_phone}
                    onChange={handleChange}
                    className="col-md-6"
                />
                <div className="col-md-12">
                    <FormCancelBtn onClick={resetAndBack} text="Cancel" />
                    <FormSaveBtn className="mr-1" isProcessing={action.isProcessing} />
                </div>
            </form>}
        </div>
    )
}


function mapStateToProps(state) {
    return {
        user: state.UserReducer.user,
    };
}

const connectedProfileUpdate = connect(mapStateToProps)(ProfileUpdate);
export { connectedProfileUpdate as ProfileUpdate };


