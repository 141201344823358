import React from 'react'
import { connect } from 'react-redux';
import { RouteConfig } from '../../../_routes'
import './style.scss'

function ProfileDetails({ user }) {

    return (
        <div className='profile-view'>
            <div className="mb-3">
                <h4 className="title" >{RouteConfig('profile').name}</h4>
            </div>
            <div className="details">
                <div className="row">
                    <p className="col-md-5 col-sm-3">Company Name:</p>
                    <h6 className="col-md-7 col-sm-9">{user.company_name}</h6>
                </div>
                <div className="row">
                    <p className="col-md-5">Public Instagram Handle:</p>
                    <h6 className="col-md-7"><a target="_blank" href={`https://www.instagram.com/${user.insta_handle}`} rel="noopener noreferrer">{`@${user.insta_handle}`}</a></h6>
                </div>
                <div className="row">
                    <p className="col-md-5">Company Representative Name:</p>
                    <h6 className="col-md-7">{user.name}</h6>
                </div>
                <div className="row">
                    <p className="col-md-5">Company Representative Email:</p>
                    <h6 className="col-md-7">{user.email}</h6>
                </div>
                <div className="row">
                    <p className="col-md-5">Company URL:</p>
                    <h6 className="col-md-7"><a target="_blank" href={`${user.company_url}`} rel="noopener noreferrer">{user.company_url}</a></h6>
                </div>
                <div className="row">
                    <p className="col-md-5">Company Phone Number:</p>
                    <h6 className="col-md-7">{user.company_phone}</h6>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.UserReducer.user,
    };
}

const connectedProfileDetails = connect(mapStateToProps)(ProfileDetails);
export { connectedProfileDetails as ProfileDetails };
