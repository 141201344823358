import {
    GENERATE_WINNERS_FAILURE, GENERATE_WINNERS_REQUEST, GENERATE_WINNERS_SUCCESS,
    GET_WINNERS_FAILURE, GET_WINNERS_REQUEST, GET_WINNERS_SUCCESS,
    GET_LATEST_WINNER_REQUEST, GET_LATEST_WINNER_SUCCESS, GET_LATEST_WINNER_FAILURE, SET_WINNERS_PAGE,
    GET_GIVEAWAY_ENTRIES_LIST_REQUEST, GET_GIVEAWAY_ENTRIES_LIST_SUCCESS, GET_GIVEAWAY_ENTRIES_LIST_FAILURE,
    GET_GIVEAWAY_PARTICIPANTS_LIST_REQUEST, GET_GIVEAWAY_PARTICIPANTS_LIST_SUCCESS, GET_GIVEAWAY_PARTICIPANTS_LIST_FAILURE,
} from "../actions";

const initialState = {
    page: 1, per_page: 18, total: 0, total_pages: 0,
    winners_loading: false, winners: [], winners_error: [],
    latest_winner_loading: false, latest_winner: {}, latest_winner_error: [],
    participants_loading: false, participants: [], contest_id: null, participants_errors: {},
    giveaway_entries_loading: false, giveaway_entries: [], giveaway_entries_errors: {},
    generate_winner_loading: false
};

export function GiveawayWinnerReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Generate winner reducer */
        case GENERATE_WINNERS_REQUEST: {
            return {
                ...state,
                latest_winner_loading: true,
                winners_loading: true,
                generate_winner_loading: true
            };
        } case GENERATE_WINNERS_SUCCESS: {

            const newData = [action.data];
            console.log(initialState, state);
            return {
                ...state,
                winners: (state.winners.length > 0) ? (newData.concat(state.winners)) : newData,
                winners_loading: false,
                latest_winner_loading: false,
                latest_winner: action.data,
                generate_winner_loading: false

            };
        } case GENERATE_WINNERS_FAILURE: {
            return {
                ...state,
                winners_loading: false,
                latest_winner_loading: false,
                winners_error: action.error,
                generate_winner_loading: false
            };
        }
        /** end::Generate winner reducer */

        /** begin::Get winners list reducer */
        case GET_WINNERS_REQUEST: {
            return {
                ...state,
                winners_loading: true
            };
        } case GET_WINNERS_SUCCESS: {
            return {
                ...state,
                winners: (action.data.winners) ? (action.data.winners) : (state.winners),
                page: (action.data.page) ? (action.data.page) : (state.page),
                per_page: (action.data.per_page) ? (action.data.per_page) : (state.per_page),
                total_pages: (action.data.total_pages) ? (action.data.total_pages) : (state.total_pages),
                total: (action.data.total) ? (action.data.total) : (state.total),
                winners_loading: false
            };
        } case GET_WINNERS_FAILURE: {
            return {
                ...state,
                winners_loading: false,
                winners_error: action.error
            };
        }
        /** end::Get winners list reducer */

        /** begin::Get latest winner reducer */
        case GET_LATEST_WINNER_REQUEST: {
            return {
                ...state,
                latest_winner_loading: true,
            };
        } case GET_LATEST_WINNER_SUCCESS: {
            return {
                ...state,
                latest_winner_loading: false,
                latest_winner: action.data[0]

            };
        } case GET_LATEST_WINNER_FAILURE: {
            return {
                ...state,
                latest_winner_loading: false,
                latest_winner_error: action.error
            };
        }
        /** end::Get latest winner reducer */

        /** begin::Get giveaway participants list reducer */
        case GET_GIVEAWAY_PARTICIPANTS_LIST_REQUEST: {
            let newArray = [...state.participants];
            if (action.request.id !== state.contest_id) {
                newArray = [];
            }
            return {
                ...state,
                participants_loading: true,
                participants: newArray
            };
        } case GET_GIVEAWAY_PARTICIPANTS_LIST_SUCCESS: {

            let newArray = [...state.participants]; //making a new array
            action.data.participants.forEach((payload) => {
                const participantIndex = newArray.findIndex(participant => participant.user_id === payload.user_id);
                if (participantIndex !== -1) {
                    newArray[participantIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });
            return {
                ...state,
                participants_loading: false,
                contest_id: action.request.id,
                participants: newArray
            };
        } case GET_GIVEAWAY_PARTICIPANTS_LIST_FAILURE: {
            return {
                ...state,
                participants_loading: false,
                participants_error: action.error
            };
        }
        /** end::Get giveaway participants list reducer */

        /** begin::Get giveaway entries list reducer */
        case GET_GIVEAWAY_ENTRIES_LIST_REQUEST: {
            return {
                ...state,
                giveaway_entries_loading: true
            };
        } case GET_GIVEAWAY_ENTRIES_LIST_SUCCESS: {

            let newArray = [...state.giveaway_entries]; //making a new array
            action.data.participants.forEach((payload) => {
                const participantIndex = newArray.findIndex(participant => participant.user_id === payload.user_id);
                if (participantIndex !== -1) {
                    newArray[participantIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                giveaway_entries_loading: false,
                giveaway_entries: newArray
            };
        } case GET_GIVEAWAY_ENTRIES_LIST_FAILURE: {
            return {
                ...state,
                giveaway_entries_loading: false,
                giveaway_entries_error: action.error
            };
        }
        /** end::Get giveaway entries list reducer */

        /** begin::Set winners page reducer */
        case SET_WINNERS_PAGE: {
            return {
                ...state,
                page: action.page
            };
        }
        /** end:: Set winners page redux */

        default: {
            return state;
        }
    }
}