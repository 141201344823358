import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { ConfzDatatable } from '../../_components/data-table'
import { GiveawayWinnerAction } from '../../_redux/actions';
import queryString from 'query-string';

function DataTable({ loading, winners, page, per_page, total, search, filter, sort, setPage, ...props }) {

    const columns = [
        { name: '#', selector: (row, i) => (row._id), center: true, width: '6%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)) },
        { name: 'Shopper', selector: (row, i) => (row.instagram.name), sortable: true, width: '20%', wrap: true },
        { name: 'Email', selector: (row, i) => (row.email), sortable: true, width: '22%', wrap: true },
        { name: 'Public Instagram Handle', selector: (row, i) => (row.insta_handle), sortable: true, width: '20%', cell: (row, i) => { return `@${row.insta_handle}` } },
        { name: 'Drawn On', selector: (row, i) => (row.created_at), sortable: true, center: true, width: '12%' },
        { name: 'Drawn Date Range', selector: (row, i) => (row.give_away_date), sortable: true, center: true, width: '20%', cell: (row, i) => { return row.give_away_date } }
    ];

    const handleSort = () => {
        console.log('Handle sort')
    }
    const handleSelect = () => {
        console.log('Handle select')
    }


    useEffect(() => {

        let queryParam = filter ? filter : {};
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = page;
        if (sort.field) {
            queryParam.field = sort.field
            queryParam.order = sort.order
        } else {
            delete queryParam.field
            delete queryParam.order
        }
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `/winners?${queryString.stringify(queryParam)}`;
        window.history.pushState({}, '', pageUrl);
        props.getAllWinners({ page: page, search: search });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, filter, sort])

    return (
        <div className="card-body card-table w-100 p-0">
            <ConfzDatatable
                columns={columns}
                data={winners}
                loading={loading}
                total={total}
                per_page={per_page}
                setPage={page => setPage(page)}
                handleSort={handleSort}
                handleSelect={handleSelect} />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.GiveawayWinnerReducer.winners_loading,
        winners: state.GiveawayWinnerReducer.winners,
        page: state.GiveawayWinnerReducer.page,
        per_page: state.GiveawayWinnerReducer.per_page,
        total: state.GiveawayWinnerReducer.total,
        total_pages: state.GiveawayWinnerReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAllWinners: (params) => dispatch(GiveawayWinnerAction.getAllWinners(params)),
        setPage: (page) => dispatch(GiveawayWinnerAction.setPage(page))
    }
};

const connectedDataTable = (connect(mapStateToProps, mapDispatchToProps)(DataTable));
export { connectedDataTable as DataTable };

