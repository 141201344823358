import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SummaryWidget } from '../../../_components/summary-widget';

function ImpressionsCount({ isLoading, impressions }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=impressions`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <SummaryWidget loading={isLoading} text={'Daily Impressions'} value={impressions} bgColor={"#008B8B"} color={"#ffffff"} />
        </Box>
    );

}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedImpressionsCount = (connect(mapStateToProps, mapDispatchToProps)(ImpressionsCount));
export { connectedImpressionsCount as ImpressionsCount };
