import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ComparisonWidget } from '../../../_components/comparison-widget';
import { SummaryWidget } from '../../../_components/summary-widget';

function DcVsEmailClicks({ isLoading, email_contacts, daily_coupons }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=email_contacts&type=dc`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <ComparisonWidget loading={isLoading} text={'Daily Coupon Count'} value={daily_coupons} text2={'Email Clicks'} value2={email_contacts} bgColor={"#008B8B"} color={"#ffffff"} />            
        </Box>
    );


}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedDcVsEmailClicks = (connect(mapStateToProps, mapDispatchToProps)(DcVsEmailClicks));
export { connectedDcVsEmailClicks as DcVsEmailClicks };
