import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
// import { withRouter } from 'react-router';
import { ConfzDatatable } from '../../_components/data-table'
import { CouponAction } from '../../_redux/actions';
import queryString from 'query-string';
import { ConfzUpdateStatus } from '../../_components/data-table/ConfzUpdateStatus';
import { ViewDetails } from './ViewDetails';



function DataTable({ loading, coupons, page, per_page, total, search, filter, sort, addCoupons, editCoupon, updateStatus, setPage, ...props }) {

    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [view, setView] = useState({ id: '', modal: false });

    const columns = [
        { name: '#', selector: (row, i) => (row._id), center: true, width: '10%', cell: (row, i) => ((per_page * (page - 1)) + (i + 1)), minWidth: 100 },
        { name: 'Coupon Code', selector: (row, i) => (row.coupon_code), sortable: true, width: '75%' },
        { name: 'Status', selector: (row, i) => (row.status), sortable: true, center: true, width: '15%', cell: row => (<StatusRow row={row} />) },
    ];


    const StatusRow = ({ row }) => {
        let status = <span className="badge badge-status badge-danger" style={{ textTransform: "capitalize" }}>{row.status}</span>;
        if (row.status === 'active')
            status = <span className="badge badge-status badge-success" style={{ textTransform: "capitalize" }}>{row.status}</span>;
        else if (row.status === 'inactive')
            status = <span className="badge badge-status badge-warning" style={{ textTransform: "capitalize" }}>{row.status}</span>;
        let statusHover = (
            <div className="btn-group row-action" role="group" >
                <span onClick={() => viewCoupon(row._id)} ><i className="fa fa-file"></i></span>
                {/* <span onClick={() => editCoupon(row._id)} ><i className="fa fa-pen"></i></span> */}
                {/* {(row.status === 'active') ? <span onClick={() => couponStatus(row._id, row.status)} ><i className="fa fa-times"></i></span> : <span onClick={() => couponStatus(row._id, row.status)} ><i className="fa fa-check"></i></span>} */}
                {/* {(row.status !== 'deleted') ? <span onClick={() => deleteCoupon(row._id)} ><i className="fa fa-trash"></i></span> : ''} */}
            </div>)
        return <React.Fragment>{status}{statusHover}</React.Fragment>;

    }


    useEffect(() => {

        let queryParam = filter ? filter : {};
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });
        queryParam.p = page;
        if (sort.field) {
            queryParam.field = sort.field
            queryParam.order = sort.order
        } else {
            delete queryParam.field
            delete queryParam.order
        }
        if (search) queryParam.q = search; else delete queryParam.q
        let pageUrl = `/coupons?${queryString.stringify(queryParam)}`;
        window.history.pushState({}, '', pageUrl);
        props.getCoupons({ page: page, search: search });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, filter, sort])


    function handleSort(page, sortDirection) {

        // console.log(page.selector, sortDirection);

    }

    const handleSelect = (data) => {
        // console.log(data.selectedRows)
    }

    const statusUpdateModal = () => {
        setStatusData({ id: '', status: '', item: '' });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    const updateStatusAction = (id) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        updateStatus(id);
    }

    const viewCoupon = (id) => {
        setView({ id: id, modal: true });
    }

    const viewModal = () => {
        setView({ id: '', modal: false });
    }




    return (
        <div className="card-body card-table w-100 p-0">
            <ConfzDatatable columns={columns} data={coupons} loading={loading} total={total} per_page={per_page} setPage={page => setPage(page)} handleSort={handleSort} handleSelect={handleSelect} />
            <ConfzUpdateStatus show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
            <ViewDetails show={view.modal} closeModal={viewModal} id={view.id} />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.CouponReducer.coupon_loading,
        coupons: state.CouponReducer.coupons,
        page: state.CouponReducer.page,
        per_page: state.CouponReducer.per_page,
        total: state.CouponReducer.total,
        total_pages: state.CouponReducer.total_pages,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addCoupons: (params) => dispatch(CouponAction.addCoupons(params)),
        getCoupons: (params) => dispatch(CouponAction.getCoupons(params)),
        updateStatus: (id) => dispatch(CouponAction.updateStatus(id)),
        setPage: (page) => dispatch(CouponAction.setPage(page))
    }
};

const connectedDataTable = (connect(mapStateToProps, mapDispatchToProps)(DataTable));
export { connectedDataTable as DataTable };

