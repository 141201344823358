import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ComparisonWidget } from '../../../_components/comparison-widget';
import { SummaryWidget } from '../../../_components/summary-widget';

function DcVsTextMessageClicks({ isLoading, text_message_clicks, daily_coupons }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=text_message_clicks&type=dc`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <ComparisonWidget loading={isLoading} text={'Daily Coupon Count'} value={daily_coupons} text2={'Text Message Clicks'} value2={text_message_clicks} bgColor={"#0ec164"} color={"#ffffff"} />            
        </Box>
    );
}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedDcVsTextMessageClicks = (connect(mapStateToProps, mapDispatchToProps)(DcVsTextMessageClicks));
export { connectedDcVsTextMessageClicks as DcVsTextMessageClicks };
