import { Box } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ComparisonWidget } from '../../../_components/comparison-widget';
import { SummaryWidget } from '../../../_components/summary-widget';

function DcVsImpression({ isLoading, impressions, daily_coupons }) {

    const navigate = useNavigate();

    const viewChart = () => {
        navigate({
            pathname: "/ig-insights/chart",
            search:`?metric=impressions&type=dc`
        })
    }

    return (
        <Box style={{cursor: 'pointer'}} onClick={() => viewChart()}>
            <ComparisonWidget loading={isLoading} text={'Daily Coupon Count'} value={daily_coupons} text2={'Impressions'} value2={impressions} bgColor={"#008B8B"} color={"#ffffff"} />            
        </Box>
    );

}


function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
};

const connectedDcVsImpression = (connect(mapStateToProps, mapDispatchToProps)(DcVsImpression));
export { connectedDcVsImpression as DcVsImpression };
